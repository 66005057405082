


@import 'scss/variables';
@import 'scss/reset';
@import 'scss/normalize';
@import 'scss/mixins';
@import 'scss/flexbox_mixin';
@import 'scss/typography';


@import 'scss/toastr';
@import 'react-redux-toastr/src/styles/index';
@import 'scss/animate';
@import 'scss/header';  



body {
    background-color: $background;
}

.error-tooltip {
    background:$ui-red !important;
    color:white !important;
}

.app-container {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.app-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    background-color: $background;
    @include flexbox();
    @include flex-direction(row);
    @include align-items();

    &:not(.collapsed) {
        .sidebar {
            @include flex(0, 0, 20vw);
        }
        .app-content {
            max-width: 80vw;
        }
    }

    div.perf-app-shell {
        display: none;
    }
}

.app-content {
    max-width: calc(100vw - 3.5em);
    @include flex(1);
    @include flexbox();
    @include flex-direction(column);
    @include align-items();

    > .header {
        @include flex(0, 0, 4em);
    }

    > .report-header {
        height: 6vh;
        flex: initial;
    }

    > .body {
        flex: 1;
        position: relative;
        overflow-y: auto;
    }
}

.app-content.app-full-screen{
    max-width: 100vw !important;
    .body {
        flex:1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
}

.icon-button {
    margin-left:5px;
    margin-right:5px;
    font-family: FontAwesome;
    font-size:1em;

}

.icon-button:hover {
    cursor:pointer;
}

.icn-file-excel-o:before {
    content:"\f1c3";
    color:rgb(0,115,57);
}

.required-fields-label {
    font-size: .7em;
    width: 100%;
    margin: 1.5em 0;
}

.guide-info-icon:after {
    cursor:pointer;
    font-family: FontAwesome;
    content:"\f05a";
    color:$ui-blue-highlight;
    font-size: 1.3em;
    margin-left: 1em;

}

textarea {
    resize: vertical;
}

.fixed-page-container {
    position: relative;
    

    & > .fixed-page-item {
        height: 100%;
        width: 100%;
        position: absolute;
        left:0px;
        top:0px;
        transition: transform 300ms ease-in;

    }

}

div[role="separator"] {
    height: 2px;
    transition: all 200ms ease;
    background-color:transparent;
    &:hover{
        position: relative;
        background-color:#CCC;
        color:#CCC;
        /*
        &::before {
            position: absolute;
            top:-22px;
            left:50%;
            margin: auto;
            content: "\f0d8";
            font-family: fontawesome;
            font-size: 20px;
        }
        &::after {
            position: absolute;
            top:2px;
            left:50%;
            margin: auto;
            content: "\f0d7";
            font-family: fontawesome;
            font-size: 20px;
            z-index: 999;
            line-height: 10px;
        }*/
    }
}

.ant-card.flex {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-width: 0;
   // height: 100%;
    max-height: 100%;

    .ant-card-body {
        flex:1;
        min-width: 0;
        overflow: auto;
    }
}
