@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/flexbox_mixin';
//@import url(https://fonts.googleapis.com/css?family=Open+Sans:700,300);
$base-font: 'Open Sans', sans-serif;
$height: 26em;
$factor: 1.618;
$duration: .6s;
$red: #FF3B99;
$blue: $incofin-blue;
$green: $incofin-green;
$orange: #FF9D00;
$textColor: #444;

body
{
    
}

.registration  {
  width: 100vw;
  height: 100vh;
  font-family: $base-font;
    font-size: 16px;

    text-align: center;

    color: $textColor;
    background: #d0d0d0;
   
    overflow: auto !important;

  section.wrapper {
    overflow:visible !important;
    min-width: 40%
  }

  .content-body {
    overflow-y: scroll;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2em;
    overflow: auto;
  }

  .logo {
    width: 160px;
    height: 5em;
  // margin-top: 10em;
    margin-bottom: 0.5em;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;
    background-image: url(/img/logo-black.png);
    text-align: center;
    user-select: none;

    span {
      text-transform: uppercase;
      font-family: 'texgyreadventor';
      font-weight: bold;
      margin-top: 1.5em;
      display: inline-block;
      font-size: 1.5em;
    }
  }

  header
  {
      margin-bottom: 2em;
  }

  h1
  {
      font-size: 2em;

      margin: 2em 0 1em;
  }

  strong
  {
      font-weight: 700;
  }
  p
  {
      line-height: 1.4em;
  }
  article header
  {
      margin: 0;
      margin-bottom:0.6em;
  }

  .full-height
  {
      height: $height;
      align-items: center;
  }
  .flexy-item
  {
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: relative;
  }
  .flexy-column
  {
      flex-direction: column;
      &.reverse
      {
          flex-direction: column-reverse;
      }
  }
  .flexy-item_child
  {
      flex: 1 0 100%;
  }

  article
  {
      //display: inline-block;
      padding: 5px;
      display: flex !important;
      flex-direction: column;
      align-items: stretch;
      justify-content: stretch;
      justify-items: stretch;
      color: white;
      margin-bottom: 3em;
      min-width: 300px;
      strong
      {
          color: $green;
          font-weight: 300;
      }
      min-height: $height;
      border-radius: .4em;
      overflow: hidden;
      box-shadow: 0 1.7em 5.5em -.94em rgba(black, .3),
        0 2em 3em .5em rgba(black, .1),
          0 1.8em 2em -1.5em rgba(black, .2);
      background-image: linear-gradient(-120deg, $green 0, $blue 100%, red 200%);
      background-position: 0 0;
      background-size: 200%;
    
    p {
      margin-bottom: 1.2em;
    }

    .article-body {
      flex: 1;
      width: 100%;
      /* margin: 1em; */
    // background-color: white;
      height: 100%;
      min-height: 100%;
      padding:1em;
    
    }

  }

  textarea {
    min-width: 80%;
    padding:0.6em;
  }


  .button-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
      justify-content: center;
  }

  button {
    margin:1em;
    user-select: none; 
    border:2px solid transparent;
  }

  button.confirm {
    
    padding: 1em;


    &.decline {
      background: $ui-red;
      &:hover {
        background: #FFF;
        color:$ui-red;
      }
    }

    &.accept {
      background: $ui-green-highlight;

      &:hover {
        background:  #FFF;;
        color:$ui-green-highlight;
      }
    }


  }

  p.highlighted {
    background-color: $ui-orange;
  /* text-shadow: -1px 1px 1px #fff,
            1px 1px 1px #fff,
            1px -1px 4px #fff,
            -1px -1px 4px #fff;*/
    //text-shadow: 1px 0px 5px rgba(255, 255, 255, 1);
  }

  .validation-form {
    input {
      width: 50%;
      display: block;
      margin: 1em auto;
      text-align: center;
      height: 2em;
    }
  }
}