@import 'scss/variables';

.missing-reporting-periodicity {
	color: $ui-red;
}

.investment-past-date {
	opacity: .55;
}


.investment-should-be-finalized {
	color: $ui-dark-red;
}
