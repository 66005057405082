@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/flexbox_mixin';

div.covenant-list {

    
    div.covenant-form {
        padding: 2em;
    }

    div.covenant-list-heading {
        @include flexbox();
        @include flex-direction(row);
        @include justify-content(space-between);

        div.filter-wrapper {
            margin-top: 1em;
        }

        div.covenant-select {
            width: 50%;
            @include flexbox();
            @include flex-direction(row);

            button {
                @include flex(1);
            }

            div.Select {
                @include flex(5);
                text-align: right;
                background: $ui-soft-yellow;
                color: $ui-blue-highlight;

                &:focus {
                    outline: none;
                }

                div.Select-control {
                    -moz-border-radius: 0;
                    -webkit-border-radius: 0;
                    border-radius: 0;
                    border: none;
                    background: transparent;
                    color: $ui-white;

                    div.Select-value {
                        padding-right: 45px;
                        border: 1px solid $ui-blue-highlight;
                        background-color: $ui-blue-highlight;
                        span.Select-value-label {
                            color: $ui-white;
                        }
                        span.Select-value-icon {
                            border: none;
                            color: $ui-white;
                        }
                    }

                    div.Select-placeholder {
                        padding-right: 40px;
                    }
                }
            }
        }
    }

    div.covenant-list-items {
        margin: 2em 0;

       

        div.covenant-list-item {
            margin: .5em 10%;
            padding: .5em;
            width: 80%;
            border: 2px dotted $ui-light-grey;
            border-bottom: 3px solid $ui-blue-highlight;
            font-size: 0.9em;

            &.covenant-archived {
                border-bottom-color: $ui-orange;
                opacity: 0.8;
               
            }

            div.covenant-archived-warning {
               background-color: $ui-orange;
               padding:0.5em;
               color:#FFFFFF;
               text-align: center;
               
            }

            div.covenant-actions {
                text-align: right;

                span {
                    padding: 0 .25em;
                    cursor: pointer;
                    @include transition(color .15s ease-in);

                    &:hover {
                        color: $ui-blue-highlight;
                    }

                    &.delete:before {
                        content: "\f014";
                        font-family: FontAwesome;
                    }

                    &.edit:before {
                        content: "\f044";
                        font-family: FontAwesome;
                    }
                }
            }

            div.covenant-title {
                @include flexbox();
                @include flex-direction(row);

                > div {
                    @include flex(1);
                }
            }

            div.covenant-name {
                &.line-through {
                    text-decoration: line-through;
                }

                span.covenant-info-guide {
                    cursor: pointer;
                    padding-right: .5em;

                    &:before {
                        color: $ui-blue-highlight;
                        content: "\f05a";
                        font-family: FontAwesome;
                        opacity: 1;
                        @include transition(opacify .3s ease);
                    }

                    &:hover:before {
                        opacity: 0.8;
                    }
                }
            }

            div.covenant-details, div.covenant-waiver-in-effect-details, div.covenant-waiver-list-item-details-range-alert {
                text-align: right;

                &.line-through {
                    text-decoration: line-through;
                }

                span {
                    padding: 0 1.5em;

                    &.covenant-details-range:before {
                        padding-right: .5em;
                        content: "\f07e";
                        font-family: FontAwesome;
                    }

                    &.covenant-details-alert-threshold:before {
                        padding-right: .5em;
                        content: "\f0f3";
                        color: $ui-dark-red;
                        text-shadow: 0 0 3px $ui-red;
                        font-family: FontAwesome;
                    }
                }
            }

            div.covenant-waiver-list-item-details-range-alert span.covenant-details-alert-threshold {
                padding-right: 0;
            }

            div.covenant-waiver-in-effect {
                div.covenant-waiver-in-effect-title {
                    padding-left: 1.5em;
                    text-transform: uppercase;

                    span.waiver-icon:before {
                        padding-right: .5em;
                        content: "\f2b5";
                        font-family: FontAwesome;
                    }

                    span:not(.waiver-icon) {
                        padding: 0 .75em;
                    }
                }

                div.covenant-waiver-in-effect-replace-covenant {
                    padding-left: 2.5em;
                }
            }

            div.covenant-waiver-list {
                padding: 0 1.5em;
                margin: .5em 0;

                div.covenant-waiver-list-header {
                    padding: .5em;
                    @include flexbox();
                    @include flex-direction(row);
                    @include justify-content(space-between);

                    &:not(.empty) {
                        border-bottom: 2px solid $ui-grey;
                    }

                    span {
                        cursor: pointer;

                        i {
                            margin-left: .5em;
                            @include transition(transform .2s);
                        }

                        &.rotate i { @include transform(rotate(90deg)); }
                    }
                }

                div.covenant-waiver-list-items {
                    height: auto;

                    &.collapsed {
                        height: 0;
                    }

                    div.covenant-waiver-list-item {
                        border-bottom: 1px solid $ui-light-grey;
                        padding: 1em .25em;

                        &.waiver-in-effect {
                            background: $ui-blue-highlight;
                            color: $ui-white;

                            div.covenant-waiver-list-item-actions span.delete:hover,
                            div.covenant-waiver-list-item-actions span.edit:hover {
                                opacity: 0.7;
                                color: $ui-white;
                            }
                        }

                        div.covenant-waiver-list-item-header {
                            @include flexbox();
                            @include flex-direction(row);
                            @include justify-content(space-between);
                        }

                        div.covenant-waiver-list-item-title {
                            text-transform: uppercase;
                            
                            > span:not(.waiver-type) {
                                padding: 0 .75em;
                            }

                            span.waiver-type {
                                text-shadow: 0 0 1px $ui-white;

                                &.DISABLE { color: $ui-red; }
                                &.REPLACE { color: #7d12cf; }
                                &.ALTER { color: $ui-orange; }
                            }
                        }

                        div.covenant-waiver-list-item-actions {
                            text-align: right;

                            span {
                                padding: 0 .25em;
                                cursor: pointer;
                                @include transition(all .15s ease-in);

                                &:hover {
                                    opacity: 1;
                                    color: $ui-blue-highlight;
                                }

                                &.delete:before {
                                    content: "\f014";
                                    font-family: FontAwesome;
                                }

                                &.edit:before {
                                    content: "\f044";
                                    font-family: FontAwesome;
                                }
                            }
                        }

                        div.covenant-waiver-list-item-details-replace {
                            padding-left: 1.5em;
                        }

                        div.covenant-waiver-list-item-details {
                            @include flexbox();
                            @include flex-direction(row);

                            > div {
                                @include flex(1);
                            }
                        }
                    }
                }
            }
        }
    }
}