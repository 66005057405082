
@import 'scss/variables';
.fperiod-item {
    margin:5px;
    border:1px solid #999;
    padding:5px;
    padding-left:2em;
    display: flex;
    flex-direction: column;

    &.fperiod-item-invalid {
        border-color: $ui-dark-red;
    }

    .fperiod-item-errors {
        ul {
            color: $ui-dark-red;
            margin-left: 2em;
            li {
               
            }
        }
       
    }

    .fperiod-item-title {
        color:black;
        font-weight: bold;
        position: relative;

        &.fperiod-item-remove {
            position: absolute;
            right: -10px;
            top:-2px;
        }
    }
    .fperiod-item-body {
        flex:1;
       // display: flex;
       // flex-direction: row;
       & > div {
        display: inline-block;
        margin-left:2px;
        margin-right:2px;
       }

        .form-group {
            //flex: 1;
        }
    }
    
}