@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/flexbox_mixin';

/* SIDEBAR STYLING - NOT COLLAPSED */
.sidebar {
    background-color: $side-background;
    color: $font-color-light;
    width: 20vw;
    height: 100%;
    position: relative;
    @include flexbox();
    @include flex-direction(column);
    @include align-items(stretch);
    @include justify-content(center);


    .logo {
        height: 15%;
        background: url('../../../img/logo-white.png') no-repeat 50% 50%;
        background-size: 80%;
    }

    .navigation {
        @include flex(1);
        overflow-x: hidden;
        overflow-y: auto;

        ul,li {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }


        // ICONS
        .icon-dash:before {
            content: "\f009";
            font-family: FontAwesome;
        }

        .icon-reports:before {
            content: "\f0f6";
            font-family: FontAwesome;
        }

        .icon-organisation:before {
            content: "\f0e8";
            font-family: FontAwesome;
        }

        .icon-financial:before {
            content: "\f155";
            font-family: FontAwesome;
        }

        .icon-social:before {
            content: "\f2bb";
            font-family: FontAwesome;
        }

        .icon-statistics:before {
            content: "\f080";
            font-family: FontAwesome;
        }

        .icon-admin:before {
            content: "\f023";
            font-family: FontAwesome;
        }

        .icon-export:before {
            //content: "\f0c7";           // disc icon
            //content: "\f0ec";           // exchange icon
            content: "\f1d8";           // paper plane icon
            //content: "\f064";           // share icon
            font-family: FontAwesome;
        }

        .icon-investments:before {
            content: "\f201";
            font-family: FontAwesome;
        }

        & > .navigation-item:before {
            width: 1em;
            display: inline-block;
        }

        .navigation-item {
            padding-left: 1.2vw;
            font-size: 1.2em;
            font-weight: bold;
            margin: .5em 0;
            @include transition(background .2s ease-out);
            cursor: pointer;
            display: flex;



            span { margin-left: .5em; display: block;flex:1}

            background-color: unset;
            color: unset;

            &.active {
                //& > span {
                background-color: $ui-blue-highlight;
                color: $light;
              //  }
                &:before {
                    background-color: $ui-blue-highlight;
                    color: $light;
                    }
            }

            &.sub-active {
                & > span {
                //background-color: $ui-blue-highlight;
                    color: lighten($ui-blue-highlight,40%);
                }
                &:before {
                    color:lighten($ui-blue-highlight,40%);
                }
            }

            &:hover {
               // & > span {
                cursor: pointer;
                background-color: rgba($ui-green-highlight, 0.8);
               // }
            }

            &.more {
                &:after {
                    font-family: FontAwesome;
                    content: '\f0d7';
                    font-size: 0.8em;
                    line-height: 2.5em;
                    padding-right: 0.5em;
                    float: right;
                    @include transition(transform .3s);
                    @include transform(rotate(0deg));
                }

                &.closed:after {
                    @include transform(rotate(-45deg));
                }
            }
        }

        .sub-navigation {
            margin-top: 0;
            margin-left: 2.3vw;
            margin-bottom: 1em;
            padding: 0;
            border-left: 2px solid $ui-grey;
            max-height: 20em;

           
            .sub-navigation {
                margin-top: 0;
                margin-left: 1em;
                margin-bottom:0.51em;
                padding: 0;
                border-width: 0px;
                border-left: 1px solid $ui-grey;
                max-height: 20em;
            }

            &.closed {
                max-height: 0;
                display: none;
            }

            .navigation-item:before {
               /* content: ' ';
                width: 0.5em;
                height: 0.5em;
                background-color: $ui-grey;
                display: inline-block;
                float: left;
                margin-top: .5em;
                margin-left: -.3em;
                @include rounded-corners(.5em, .5em, .5em, .5em);*/
            }

            .navigation-item {
                font-weight: normal;
                font-size: 1em;
                list-style-type: none;
                padding: 0;
                margin: 0;

                

                span {
                    display: block;
                    margin-left: -.5em;
                    padding-left: 1.3em;
                }

                &:hover:before {
                    background-color: white;
                }
            }
        }
    }

    .contact-list {
        height: 25%;
        overflow-x: hidden;
        overflow-y: auto;

        > div {
            position: absolute;
            bottom: 0;
            width: 100%;
        }

        .title {
            padding-left: 1em;
            font-size: 1.2em;
            font-weight: bold;

            &:before {
                content: '\f0c0';
                font-family: FontAwesome;
            }

            span {
                margin-left: 0.5em;
            }
        }

        .contact {
            padding-left: 2em;
            margin: 0.4em 0;

            &:before {
                content: '\f0e6';
                font-family: FontAwesome;
                margin-right: .3em;
            }

            a.name {
                color: $light;
                text-decoration: none;
                font-size: 1em;
                font-weight: normal;
            }

            span {
                display: block;
                color: rgb(121, 121, 121);

                &.role {
                    font-size: .8em;
                }
            }
        }
    }
}

/* SIDEBAR - COLLAPSED*/
.app-wrapper.collapsed {
    .sidebar {
        width: 3.5em;

        .logo {
            height:3em;
            margin-top: 0.5em;
            background-image: url('/img/logo-icon.svg');
            background-size:contain;
        }

        .sub-navigation {
            display: none;
            position: absolute;
            padding: 0;
            width: 15vw;
            background: $side-background;
            margin-left: 3.5em;
            margin-top: -1.2em;
            -moz-border-bottom-right-radius: 4px;
            -webkit-border-bottom-right-radius: 4px;
            -o-border-bottom-right-radius: 4px;
            border-bottom-right-radius: 4px;
            -moz-border-top-right-radius: 4px;
            -webkit-border-top-right-radius: 4px;
            -o-border-top-right-radius: 4px;
            border-top-right-radius: 4px;

            .navigation-item {
                span {
                    display: inline-block !important;
                }

                &:before {
                    margin-top: 0.3em !important;
                    margin-left: -0.3em !important;
                }
            }
        }

        .navigation {
            .navigation-item {
                padding-left: 0.75em;

                span {
                    display: none;
                }

                &:before {
                    font-size: 1.5em;
                    margin: 0;
                    padding: 0;
                    font-weight: normal;
                    text-align: center;
                }

                &.more:after {
                    display: none;
                }
            }
        }

        .contact-list {
            .title {
                padding-left: 0.75em;

                &:before {
                    font-size: 1.5em;
                }

                span {
                    display: none;
                }
            }

            .contact {
                display: none;
            }
        }
    }
}
