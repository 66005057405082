@import 'scss/variables';
@import 'scss/flexbox_mixin';


div.text-field, div.numeric-field, div.date-field, div.input-field, div.select-field {
    width: 100%;
    padding: 2px 0px;
    @include flexbox();
    @include flex-direction(row);
    @include flex-wrap(nowrap);

    input {
        @include flex-grow(6);
        width: initial !important;
    }


    input, textarea {
        color: inherit;
        background: transparent;
        border: none;
        text-align: right;
        width: 100%;
        font-size: .9em;

        &.invalid {
            border-bottom: 1px solid $ui-red;
        }

        &.negative {
            color: $ui-red !important;
        }
    }

    span.field-post-text {
        color: $dark;
        font-size: .7em;
        padding-left: 4px;
        @include flexbox();
        @include align-items(center);
        opacity:0.8;
    }

    span.invalid-text {
        visibility: hidden;
        color: $ui-red ;

        font-size: 10px;
        &:after {
            text-shadow: 0px 0px 2px rgba(255, 255, 255, 1);
            cursor:pointer;
            font-family: FontAwesome;
            content:"\f071";
            font-size: 1em;
            padding:3px;
            color:$ui-red !important;
        }
    }

    &.invalid {
        span.invalid-text {
            visibility: visible;
        }
        input, textarea {
            border-bottom: 1px solid $ui-red;
        }
    }
}

div.select-field {
    span.field-post-text {
        display: hidden;
    }
}

div.text-field, div.date-field, div.input-field  {


    //span { @include flex-grow(1); }
}

.Select {
    padding: 3px 2%;
    font-size: .9em;
}

.Select.invalid > .Select-control {
    border-bottom: 1px solid $ui-red !important;
}

.is-focused:not(.is-open) > .Select-control {
    border: none;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
