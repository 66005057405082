@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/flexbox_mixin';

.field-wrapper {
    width: 100%;
    margin-bottom: 2em;

    .invalid-text {
        font-size: .8em;
        opacity: 0;
        text-align: right;
        @include transition(opacity .2s ease-in);
    }

    .field-container {
        @include flexbox();
        @include flex-direction(row);
        @include align-items();
        @include transition(border-bottom-color .2s ease-in);
        font-size: 1.2em;
        border-bottom: 1px solid grey;
        

        label {
            text-transform: uppercase;
            font-weight: bold;
            font-family: $font-family-title;
            @include flex(1);
            @include transition(color .2s ease-in);
        }

        .pw-wrapper {
            @include flex(1);
            overflow: hidden;
        }

        input {
            @include flex(1);
            border-width: 0;
            text-align: right;
            color: $ui-blue-highlight;

            &:focus {
                outline: none;
            }

            &[type=password] {
                width: 100%;
                font-weight: bold;
                letter-spacing: .3em;
                line-height: .5;
                font-size: 1.3em;
                padding: 0;
                padding-right: 0.5em;
                margin: 0;
            }

            &::-moz-placeholder {
                font-family: $font-family-sans;
                font-size: 1rem;
                letter-spacing: normal;
                font-weight: normal;
            }

            &:-ms-input-placeholder {
                font-family: $font-family-sans;
                font-size: 1rem;
                letter-spacing: normal;
                font-weight: normal;
            }

            &::-webkit-input-placeholder {
                font-family: $font-family-sans;
                font-style: italic;
                font-size: 1rem;
                letter-spacing: normal;
                font-weight: normal;
            }

        }
    }

    &.invalid {
        .field-container {
            border-bottom-color: $ui-red;

            label {
                color: $ui-dark-red;
            }

            &:after {
                position: relative;
                top: .5em;
                left: 1.2em;
                content: '';
                @include triangle($orientation: 'left', $width: 0.8em, $height: 0.8em, $backgroundColor: $ui-dark-red);
            }
        }

        & .invalid-text {
            color: $ui-dark-red;
            opacity: 1;
        }

    }

    &.hasfocus {
        .field-container {
            border-bottom-color: $ui-blue-highlight;

            label {
                color: $ui-blue-highlight;
            }
        }
    }

}