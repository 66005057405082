@import 'scss/variables';
@import 'scss/mixins';

.c-dashboard {
  padding: 0;

  > .o-grid:first-child {
    margin-top: 0;
  }

  @include respond-to('medium') {
    padding: .5rem;
  }
  @include respond-to('large') {
    padding: 1rem;
  }
}
