/* MIXINS */
@mixin triangle($orientation:'right',$width:20px,$height:20px,$backgroundColor:#000) {
  $heightSplit:$height*0.5;
  $widthSplit :$width*0.5;

  width: 0;
  height: 0;
  border-style: solid;

  @if $orientation == 'right' {
    border-width: $heightSplit 0 $heightSplit $width;
    border-color: transparent transparent transparent $backgroundColor;
  } @else if $orientation == 'left' {
    border-width: $heightSplit $width $heightSplit 0 ;
    border-color: transparent $backgroundColor transparent transparent ;
  } @else if $orientation == 'top' {
    border-width: 0 $widthSplit $height $widthSplit;
    border-color: transparent transparent $backgroundColor transparent;
  } @else if $orientation == 'bottom' {
    border-width: $height $widthSplit 0 $widthSplit;
    border-color: $backgroundColor transparent transparent transparent;
  } @else if $orientation == 'topleft' {
    border-width: $height $width 0 0;
    border-color: $backgroundColor transparent transparent transparent;
  } @else if $orientation == 'topright' {
    border-width: 0 $width $height 0;
    border-color: transparent $backgroundColor transparent transparent;
  } @else if $orientation == 'bottomleft' {
    border-width: $height $width 0 0;
    border-color: transparent transparent transparent $backgroundColor;
  } @else if $orientation == 'bottomright' {
    border-width: 0 0 $height $width;
    border-color: transparent transparent $backgroundColor transparent;
  }
}

@mixin transform($val) {
    -webkit-transform: $val;
    -moz-transform: $val;
    -ms-transform: $val;
    -o-transform: $val;
    transform: $val;
}

@mixin transition( $val : ease 0.5s ) {
  -webkit-transition: $val;
  -moz-transition:$val;
  -o-transition:$val;
  -ms-transition:$val;
  transition:$val;
}

@mixin rotate( $val: -2deg ) {
  -webkit-transform: rotate($val);
  -moz-transform: rotate($val);
  -ms-transform: rotate($val);
  -o-transform: rotate($val);
  transform: rotate($val);
}

@mixin text-shadow( $top: 3px, $left: 3px, $blur: 3px , $colour: #333 ) {
  -webkit-text-shadow:$top $left $blur $colour; 
  -moz-text-shadow:$top $left $blur $colour; 
  -o-text-shadow:$top $left $blur $colour; 
  text-shadow:$top $left $blur $colour; 
}

@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow:  $shadow;
  box-shadow: $shadow;
}

@mixin rounded-corners($tl : 4px, $tr : 4px, $br: 4px, $bl: 4px) {
  -webkit-border-radius:$tl $tr $br $bl;
  -moz-border-radius:$tl $tr $br $bl;
  -o-border-radius:$tl $tr $br $bl;
  border-radius:$tl $tr $br $bl;
}

@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

@mixin transition-property($property...) {
    -moz-transition-property:    $property;
    -o-transition-property:      $property;
    -webkit-transition-property: $property;
    transition-property:         $property;
}

@mixin transition-duration($duration...) {
    -moz-transition-property:    $duration;
    -o-transition-property:      $duration;
    -webkit-transition-property: $duration;
    transition-property:         $duration;
}

@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function:    $timing;
    -o-transition-timing-function:      $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function:         $timing;
}

@mixin transition-delay($delay...) {
    -moz-transition-delay:    $delay;
    -o-transition-delay:      $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}

@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model; // Safari <= 5
     -moz-box-sizing: $box-model; // Firefox <= 19
          box-sizing: $box-model;
}

@mixin linearGradient($top, $bottom){
    background: $top; /* Old browsers */
    background: -moz-linear-gradient(top,  $top 0%, $bottom 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  $top 0%,$bottom 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  $top 0%,$bottom 100%); /* IE10+ */
    background: linear-gradient(to bottom,  $top 0%,$bottom 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}


/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

    // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin user-select($props...) {
  -webkit-user-select: $props;
  -moz-user-select: $props;
  -ms-user-select: $props;
  -o-user-select: $props;
  user-select: $props;
}

@mixin input-placeholder() {
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin keyframe ($animation_name) {
    @-webkit-keyframes #{$animation_name} {
        @content;
    }

    @-moz-keyframes #{$animation_name} {
        @content;
    }

    @-o-keyframes #{$animation_name} {
        @content;
    }

    @keyframes #{$animation_name} {
        @content;
    }
}

@mixin animation ($delay, $duration, $animation) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;

    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;

    -o-animation-delay: $delay;
    -o-animation-duration: $duration;
    -o-animation-name: $animation;

    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
}