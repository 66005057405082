@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/flexbox_mixin';

.inco-table.inco-financialperiods {
    margin-top: 2em;
   // width: 100%;
    background: $ui-white;
    border-spacing: 0;
    font-size: .8rem;
    thead {
        th {
            position: relative;
            padding: 0.5rem;
            text-align: left;
            font-size: 1.1em;
            font-weight: bold;
            color: $ui-white !important;
            background-color: rgba($side-background,0.8) !important;
            border-left: 1px solid $ui-light-grey;
            border-top: 1px solid $ui-light-grey;

            &:last-child {
                border-right: 1px solid $ui-light-grey;
            }

            span.column-sorted {
                color: $ui-blue-highlight !important;
            }

            span.column-sort-asc:after {
                    font-family: FontAwesome;
                    content: "\f0de";
                    
                
                
            }
    
            span.column-sort-desc:after {
                    font-family: FontAwesome;
                    content: "\f0dd";
                    
                
                
            }
        }
    }
    tbody {
        tr {
            &.error td {
                color: $ui-red;
                font-weight: bold;
            }
            &:nth-child(even) td {
                background-color: white
            }
            &.overdue td {

            }
        }
        td {
            padding: .5rem;

            &:not(.footer-container) {
                border-left: 1px solid $ui-light-grey;
                border-bottom: 1px solid $ui-light-grey;

                &:last-child {
                    border-right: 1px solid $ui-light-grey;
                }

                button {
                    background: rgb(255, 253, 164);
                    color: rgb(0, 0, 64);
                    float: initial;
                    margin-left: .5em;
                    padding: 3px 10px;
                    font-size: .9em;
                    font-weight: bolder;
                    line-height: .8;
                    border: 1px solid red;
                    text-transform: none !important;
                    @include rounded-corners();
                    @include transition(all 0.2s ease-in);

                    &:hover {
                        background: $ui-blue-highlight;
                        color: $ui-white;
                        border-color: $ui-blue-highlight;
                    }
                }
            }
        }

        &:last-child {
           // background: white;
        }
    }

   
}