

.tiles { 
        
        grid-gap: 2em;
        
        padding: 2em;
        display: flex;

    div {
        text-align: center;
        display: flex;
        align-items: center;
        min-height: 100px;
        background-color: #535353;
        color: white;
        font-weight: bold;
        justify-content: center;
        flex: 1;

        &:hover {
            background-color: #12B6CF;
            cursor: pointer;
        }
    }
}