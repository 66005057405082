@import 'scss/variables';

.inco-table {

    .column-sortable {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        cursor: pointer;

        .column-title {
            flex: 1;
        }

        .column-sort-icon {
            display: none;

        }
        &.column-sortable-active {
            color: $ui-blue-highlight !important;
            .column-sort-icon {
            display: inline-block;
            width: 16px;
            opacity: 1;
            }
        }

        &.column-sortable-asc {
            .column-sort-icon:before {
                font-family: FontAwesome;
                content: "\f0de";
                
            }
            
        }

        &.column-sortable-desc  {
            .column-sort-icon:before {
                font-family: FontAwesome;
                content: "\f0dd";
                
            }
            
        }


    }
    
    &.inco-table-compact {
        display: flex;
        flex-direction: column;
        padding-bottom: 25px;
        align-items: stretch;
        .filter-wrapper {
            margin:5px;
            //height: 22px;
            width: auto;
            font-size: 1rem;;
           

        }

        

        .rc-table-container {
            flex:1;
            display:flex;
            flex-direction: column;
            .rc-table-header {

            }
            .rc-table-body {
flex:1;
            }
        } 
        
        .rc-table{
            display: flex;
            flex:1;
            margin: 0px;
            max-height: 100%;

            

            .rc-table-header table {
                margin:0px;
            }

            .rc-table-body table {
                margin:0px;
            }
            .rc-table-body {
                overflow-y:scroll;
                max-height: 100%;
            }
        }
    }
}