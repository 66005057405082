.adminrates {
    .griddle-container {
      width: 80%;
    }

    .panel-component {
      .grids {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
     
    }
    .rates-grid {
      flex:1;
      max-width:50%;
      margin:10px;
      //display: inline-block;
    }

    .quota-grid {
      flex:1;
      margin:10px;
     // display: inline-block;
      td:nth-child(odd) {
        text-align: right;
      }
    }
}