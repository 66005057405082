@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/flexbox_mixin';

@keyframes fadeOut {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInUp {
    animation-name: fadeInUp;
    animation-duration: 1s;
    animation-fill-mode: both;
}

.logon-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    @include flexbox();
    @include flex-direction(row);
    @include align-items(center);
    @include justify-content(center);

    button, input[type=submit] {
       
        float: right;
        text-transform: uppercase;
    }

    
}

.hide {
    animation-name: fadeOut;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.logon {
    min-width:400px;
    max-width: 100%;
    width: 40%;
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);

    .logo {
        width: 50%;
        height: 5em;
        margin-bottom: 6em;
        background-repeat: no-repeat;
        background-position: center top;
        background-size: contain;
        background-image: url(/img/logo-black.png);
        text-align: center;

        span {
            text-transform: uppercase;
            font-family: 'texgyreadventor';
            font-weight: bold;
            margin-top: 2.5em;
            display: inline-block;
            font-size: 1.5em;
        }
    }

    div.logon-body {
        width: 100%;
    }

    .action-wrapper {
        margin-top: 2em;

        button {
            float: right;
            clear: both;
        }

        a {
            float: right;
            clear: both;
            text-decoration: none;
            color: $ui-blue-highlight;

            &:visited, &:active, &:hover {
                color: $ui-blue-highlight;
            }

        }
    }
}

.rc-pagination {

    margin-top:0.5em;

    .rc-pagination-item , .rc-pagination-prev button, .rc-pagination-next button {
        display: inline-block;
        background-color: $ui-green-highlight;
        color: $light-extra;
        font-family: $font-family-sans;
        font-size: 1em;
        border-width: 0;

        a {
            color: $light-extra;
            font-family: $font-family-sans;
            font-size: 1em;
        }
       
    
        &:hover {
            cursor: pointer;
            background-color: $ui-blue-highlight;
        }
        &.rc-pagination-item-active {
            background: $ui-blue-highlight;
        }
    }

  
}



// button, input[type=submit] {
// feature/issue-197
button, input[type=submit], input[type=button] {
    background-color: $ui-green-highlight;
    color: $light-extra;
    font-family: $font-family-sans;
    font-size: 1em;
    border-width: 0;
    padding: 0.5em 1.2em 0.5em 1.2em;
    text-align: center;
   
    @include transition(background-color 0.3s ease-out);

    &:hover {
        cursor: pointer;
        background-color: $ui-blue-highlight;
    }

    &:focus {
        border: 0;
        outline: none;
        background-color: $ui-blue-highlight;
    }

    &.large-button {
        font-size: 1.5em;
    }

    &.cancel {
        background: $ui-red;
    }

    &.takeAction {
        background: $ui-orange;
    }

}

@keyframes slideInRight {
    0% {
        opacity: 0;
        transform: translate3d(100vw, 0, 0);
    }

    50% {
        opacity: 1;
        transform: none;
    }

    75% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes slideInLeft {
    0% {
        opacity: 0;
        transform: translate3d(-100vw, 0, 0);
    }

    50% {
        opacity: 1;
        transform: none;
    }

    75% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }

}

.loader.slideIn {
    display: block;

    .blue-circle {
        animation-name: slideInRight;
        animation-duration: 2s;
        animation-fill-mode: both;
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-iteration-count: 1;
    }

    .green-circle {
        animation-name: slideInLeft;
        animation-duration: 2s;
        animation-fill-mode: both;
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-iteration-count: 1;
    }
}

.loader.loading {
    display: block;

    .blue-circle, .green-circle {
        animation-name: rotate !important;
        animation-duration: 2s;
        animation-fill-mode: both;
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-iteration-count: infinite;
    }
}

.loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: none;

    .logo-wrapper {
        position: absolute;
        height: 20vh;
        width: 20vw;
        top: 50%;
        margin-top: -20vh;
        margin-left: 40vw;
    }

    .blue-circle {
        width: 20vw;
        height: 20vh;
        background-repeat: no-repeat;
        -webkit-background-size: contain;
        background-size: contain;
        background-position: center center;
        background-image: url('/img/top_logo.svg');
    }

    .green-circle {
        width: 20vw;
        height: 20vh;
        position: relative;
        top: -20vh;
        background-repeat: no-repeat;
        -webkit-background-size: contain;
        background-size: contain;
        background-position: center center;
        background-image: url('/img/bottom_logo.svg');
    }
}

