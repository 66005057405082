@font-face {
  font-family: 'texgyreadventor';
  src: url('/fonts/texgy/texgyreadventor-regular-webfont.eot');
  src: url('/fonts/texgy/texgyreadventor-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/texgy/texgyreadventor-regular-webfont.woff') format('woff'),
  url('/fonts/texgy/texgyreadventor-regular-webfont.ttf') format('truetype'),
  url('/fonts/texgy/texgyreadventor-regular-webfont.svg#texgyreadventorregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'texgyreadventor';
  src: url('/fonts/texgy/texgyreadventor-italic-webfont.eot');
  src: url('/fonts/texgy/texgyreadventor-italic-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/texgy/texgyreadventor-italic-webfont.woff') format('woff'),
  url('/fonts/texgy/texgyreadventor-italic-webfont.ttf') format('truetype'),
  url('/fonts/texgy/texgyreadventor-italic-webfont.svg#texgyreadventoritalic') format('svg');
  font-weight: normal;
  font-style: italic;

}


@font-face {
  font-family: 'texgyreadventor';
  src: url('/fonts/texgy/texgyreadventor-bold-webfont.eot');
  src: url('/fonts/texgy/texgyreadventor-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/texgy/texgyreadventor-bold-webfont.woff') format('woff'),
  url('/fonts/texgy/texgyreadventor-bold-webfont.ttf') format('truetype'),
  url('/fonts/texgy/texgyreadventor-bold-webfont.svg#texgyreadventorbold') format('svg');
  font-weight: bold;
  font-style: normal;

}

@font-face {
  font-family: 'texgyreadventor';
  src: url('/fonts/texgy/texgyreadventor-bolditalic-webfont.eot');
  src: url('/fonts/texgy/texgyreadventor-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/texgy/texgyreadventor-bolditalic-webfont.woff') format('woff'),
  url('/fonts/texgy/texgyreadventor-bolditalic-webfont.ttf') format('truetype'),
  url('/fonts/texgy/texgyreadventor-bolditalic-webfont.svg#texgyreadventorbold_italic') format('svg');
  font-weight: bold;
  font-style: italic;

}



