@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/flexbox_mixin';

div.resetpassword {
    width: 100%;
}

div.resetpassword-form-wrapper {
  width: 100%;
}

div.resetpassword-form {
  width: 100%;
  text-align: center;
}

div.resetpassword-form {
  text-align: center;

  p {
    text-transform: uppercase;
    font-weight: bold;
    font-family: "texgyreadventor";
    color: #535353;
  }

  input {
    width: 60%;
    display: block;
    margin: 1em auto;
    text-align: center;
    background-color: white;
    border: none;
    border-bottom: 1px solid black;
    height: 2em;
    background-color: rgb(250, 255, 189);

    &.token-input {
      width: 20%;
    }

    &:focus {
      color: #12B6CF;
      border-bottom: 1px solid #12B6CF;
    }
  }

  button {
    margin: 1em auto;
    display: block;
    float: none;
    text-align: center;
  }
}