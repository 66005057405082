@import 'scss/variables';
@import 'scss/mixins';

div.multi-client-container {
    width: 100%;

    h2.current {
        cursor: pointer;
        &:after {
            margin-left: 10px;
            display: inline-block;
            content: "\f0d7";
            font-family: FontAwesome;
            @include transition(transform .3s);
            @include transform(rotate(-45deg));
        }


    }


    div.multi-client-options {
        width: 30%;
        position: absolute;
        background-color: rgba($side-background,0.8);
        color: white;
        padding: 1em;
        display: none;



        h3 {
            padding: .5em;
            cursor: pointer;
            @include transition(background .1s ease-in);

            &:hover {
                background: rgba($ui-green-highlight, 0.8);
            }
        }

        div.clients { margin-bottom: .5em; }

        div.text-field {
            background: white;
            padding: .5em;

            input {
                text-align: left;
                color: $side-background;
            }
        }
    }

    &.open {
        h2.current:after {
            @include transform(rotate(0deg));
        }

        div.multi-client-options {
            display: block;
            @include animation(0, .3s, fadeIn);
        }
    }
}

@include keyframe(fadeIn) {
    0%   { opacity: 0; @include transform(translate3d(0, 100px, 0)); }
    100% { opacity: 1; @include transform(translate3d(0, 0, 0)); }
}