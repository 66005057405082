// summary-block
@import 'scss/flexbox_mixin';

.text-block {
  @include flexbox();

  .text-block__icon {
    line-height: 1;
    margin-right: .5rem;
  }
  .text-block__text {
    @include flex();
    line-height: 1.2;
    word-wrap: break-word;
  }
}
