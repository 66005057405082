@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/flexbox_mixin';

.menu-wrapper {
    margin-right: .5em;

    .menu-item {
        max-height: 1.8em;
        border-radius: 5px;
        -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);
        cursor: pointer;
        background-color: $ui-white;
        color: $dark;

        @include flexbox();
        @include flex-direction(row);
        @include align-items(stretch);
        @include justify-content(center);

        .menu-item-text {
            background-color: $font-color-medium;
            color: $ui-white;
            @include flex(1);
            overflow: hidden;

            span {
                margin-left: 0.5em;
                margin-right: 0.5em;
                text-overflow: ellipsis;
            }
        }

        .menu-item-icon {
            vertical-align: middle;
            font-size: 1em;
            padding-left: 0.5em;
            padding-right: 0.5em;
            width: 2em;
            text-align: center;
            font-family: FontAwesome;

            &:before {
                content: "\f0d6";
            }
        }

        &:hover {
            .menu-item-text {
                background-color: rgba($ui-green-highlight, 0.8);
            }

            .menu-item-icon {
                &:before {
                    content: "\f0dd" !important;
                }
            }
        }
    }

    .menu-sub-wrapper {
        max-height: 40vh;
        position: absolute;
        z-index: 999;
        overflow-y: auto;
        background-color: white;
        border: 1px solid $font-color-medium;
        transition: opacity 500ms;

        ul {
            list-style-type: none;

            li {
                padding: .2em .5em .2em .5em;
                display: block;
                white-space: nowrap;

                .menu-sub-item-icon {
                    display: inline-block;
                    width: 2em;
                    color: $font-color-medium;
                    font-family: FontAwesome;
                }

                &.menu-item-default {
                    background-color:$ui-soft-yellow;
                }

                &:hover {
                    background-color: rgba($ui-green-highlight, 0.8);
                    color: $ui-white;
                    cursor: pointer;
                }


            }
        }
    }
}