@import 'scss/variables';

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.spinner {
 
  display: flex;
  flex-direction: column;
  align-items: center;
  
  &.fadeIn {
    animation-name: fadeIn;
    animation-duration:1000ms;
    animation-fill-mode: backwards;
  }
 // margin: 100px auto;

  .spinner-body {
    position: relative;
    width: 40px;
    height: 40px;
    
  }

  .spinner-label {
    margin-top: 20px;
    text-align: center;
  }
}
.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: $ui-blue-highlight;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
  background-color: $ui-green-highlight;
}


@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
}