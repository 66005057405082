// summary-block
@import 'scss/variables';

.summary-block {
  font-size: 1em;
}

.summary-block__heading {
  margin-bottom: .5em;
  font-size: 1em;
  font-weight: 300;
  color: $summary-header-color;
}

.summary-block__content {
  font-size: 1.5em;
  font-weight: 600;
  color: $summary-text-color;
}

.summary-block__footer {
  margin-top: 1em;
  font-size: .9em;
  color: $summary-footer-color;
  text-align: right;
}