@import 'scss/variables';
@import 'scss/mixins';

.panel-container {
  background: $light;
  padding: 1.5rem;

  .panel-title {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    border-bottom: 2px solid $ui-light-grey;
    .panel-title-txt {
      flex:1;
      font-size: 1.4em;
     

      .subtitle {
        font-size: .6em;
        margin-left: .5rem;
        @include opacity(.7);
      }
    }
    .panel-title-extras {
    
    }
  }
}