@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/flexbox_mixin';

.o-grid {
  margin: .5rem 0;
  padding: 0;
  @include flexbox();
  @include flex-flow(row wrap);
}

.o-grid__item {
  padding: .5rem;
  @include flex(1 0 0);
  max-width: 100%;

  &--xs-1 {
    @include flex-basis(8.33333333%);
    max-width: 8.33333333%;
  }

  &--xs-2 {
    @include flex-basis(16.66666667%);
    max-width: 16.66666667%;
  }

  &--xs-3 {
    @include flex-basis(25%);
    max-width: 25%;
  }

  &--xs-4 {
    @include flex-basis(33.33333333%);
    max-width: 33.33333333%;
  }

  &--xs-5 {
    @include flex-basis(41.66666667%);
    max-width: 41.66666667%;
  }

  &--xs-6 {
    @include flex-basis(50%);
    max-width: 50%;
  }

  &--xs-7 {
    @include flex-basis(58.33333333%);
    max-width: 58.33333333%;
  }

  &--xs-8 {
    @include flex-basis(66.66666667%);
    max-width: 66.66666667%;
  }

  &--xs-9 {
    @include flex-basis(75%);
    max-width: 75%;
  }

  &--xs-10 {
    @include flex-basis(83.33333333%);
    max-width: 83.33333333%;
  }

  &--xs-11 {
    @include flex-basis(91.66666667%);
    max-width: 91.66666667%;
  }

  &--xs-12 {
    @include flex-basis(100%);
    max-width: 100%;
  }

  @include respond-to('small') {
    &--sm-1 {
      @include flex-basis(8.33333333%);
      max-width: 8.33333333%;
    }

    &--sm-2 {
      @include flex-basis(16.66666667%);
      max-width: 16.66666667%;
    }

    &--sm-3 {
      @include flex-basis(25%);
      max-width: 25%;
    }

    &--sm-4 {
      @include flex-basis(33.33333333%);
      max-width: 33.33333333%;
    }

    &--sm-5 {
      @include flex-basis(41.66666667%);
      max-width: 41.66666667%;
    }

    &--sm-6 {
      @include flex-basis(50%);
      max-width: 50%;
    }

    &--sm-7 {
      @include flex-basis(58.33333333%);
      max-width: 58.33333333%;
    }

    &--sm-8 {
      @include flex-basis(66.66666667%);
      max-width: 66.66666667%;
    }

    &--sm-9 {
      @include flex-basis(75%);
      max-width: 75%;
    }

    &--sm-10 {
      @include flex-basis(83.33333333%);
      max-width: 83.33333333%;
    }

    &--sm-11 {
      @include flex-basis(91.66666667%);
      max-width: 91.66666667%;
    }

    &--sm-12 {
      @include flex-basis(100%);
      max-width: 100%;
    }
  }

  @include respond-to('medium') {
    &--md-1 {
      @include flex-basis(8.33333333%);
      max-width: 8.33333333%;
    }

    &--md-2 {
      @include flex-basis(16.66666667%);
      max-width: 16.66666667%;
    }

    &--md-3 {
      @include flex-basis(25%);
      max-width: 25%;
    }

    &--md-4 {
      @include flex-basis(33.33333333%);
      max-width: 33.33333333%;
    }

    &--md-5 {
      @include flex-basis(41.66666667%);
      max-width: 41.66666667%;
    }

    &--md-6 {
      @include flex-basis(50%);
      max-width: 50%;
    }

    &--md-7 {
      @include flex-basis(58.33333333%);
      max-width: 58.33333333%;
    }

    &--md-8 {
      @include flex-basis(66.66666667%);
      max-width: 66.66666667%;
    }

    &--md-9 {
      @include flex-basis(75%);
      max-width: 75%;
    }

    &--md-10 {
      @include flex-basis(83.33333333%);
      max-width: 83.33333333%;
    }

    &--md-11 {
      @include flex-basis(91.66666667%);
      max-width: 91.66666667%;
    }

    &--md-12 {
      @include flex-basis(100%);
      max-width: 100%;
    }
  }

  @include respond-to('large') {
    &--lg-1 {
      @include flex-basis(8.33333333%);
      max-width: 8.33333333%;
    }

    &--lg-2 {
      @include flex-basis(16.66666667%);
      max-width: 16.66666667%;
    }

    &--lg-3 {
      @include flex-basis(25%);
      max-width: 25%;
    }

    &--lg-4 {
      @include flex-basis(33.33333333%);
      max-width: 33.33333333%;
    }

    &--lg-5 {
      @include flex-basis(41.66666667%);
      max-width: 41.66666667%;
    }

    &--lg-6 {
      @include flex-basis(50%);
      max-width: 50%;
    }

    &--lg-7 {
      @include flex-basis(58.33333333%);
      max-width: 58.33333333%;
    }

    &--lg-8 {
      @include flex-basis(66.66666667%);
      max-width: 66.66666667%;
    }

    &--lg-9 {
      @include flex-basis(75%);
      max-width: 75%;
    }

    &--lg-10 {
      @include flex-basis(83.33333333%);
      max-width: 83.33333333%;
    }

    &--lg-11 {
      @include flex-basis(91.66666667%);
      max-width: 91.66666667%;
    }

    &--lg-12 {
      @include flex-basis(100%);
      max-width: 100%;
    }
  }
}