

@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/flexbox_mixin';

.app-content > .body {
    overflow: hidden;
}


.full-screen {
    position: absolute;
    top:0px;
    left:0px;
    height: 100vh;
    width: 100vw;
    padding:1em;


    .ant-card.reporting-wizard {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        height: 100%;
       //border:1px solid $side-background;
        box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);

        .ant-card-head {

        }

        .ant-card-body {
            flex:1;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            padding:0px;
        }

        


    }

}

.ant-drawer-content-wrapper:has(.reporting-wizard ) {
    min-width: fit-content;
}

.reporting-wizard {
    min-width: fit-content;

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        overflow: hidden;
        min-width: fit-content;
        padding-top: 5px;
    }

    .report-header {
        span.header-details {
            color: $ui-blue-highlight;
            text-transform: uppercase;
        }

        span.header-details.blue {
            color: $ui-dark-blue;
        }

        span.header-details.orange {
            color: $ui-orange;
        }

        span.icon-close{
            cursor: pointer;
            float: right;
            margin-right: 1em;

            &:before {
                content: "\f00d";
                font-family: FontAwesome;
            }
        }
    }

    div[data-panel] {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .wizard-wrapper {
        flex:1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        overflow: hidden;

        .wizard-enable-edit {   display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            padding-top: 0.5em;
            padding-bottom: 0.5em;
            background: $ui-soft-blue;
        }

        .body-wrapper {
            flex:1;
            padding: 1em;
            overflow: hidden;
            display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid #f0f0f0;

            .form-flat {
                
                //border:1px solid $ui-dark-blue;
            }
        }

        .wizard-actions {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-left: 2em;
            padding-right: 2em;
            padding-top: 1em;
            padding-bottom: 1em;
        }
    }

    section.step-indicator {
        margin-bottom: 0em;
        height: 30px;
       overflow: hidden;
        @include flexbox();
        @include flex-direction(row);
        @include flex-wrap(nowrap);
    
        .step {
            @include flex-grow(1);
            display: block;
            float: left;
            background: $side-background;
            padding-right: 10px;
            height: 30px;
            line-height: 32px;
            margin-right: 33px;
            position: relative;
            text-decoration: none;
            color: $font-color-light;
            cursor: default;
            font-size: 1em;
            @include transition(opacity .2s ease-in);
    
            &.clickable:hover {
                opacity: 0.8;
                text-decoration: underline;
                cursor: pointer;
            }
    
            &:before {
                content: "";
                display: block;
                width: 0;
                height: 0;
                position: absolute;
                top: 0;
                left: -30px;
                border: 15px solid transparent;
                border-color: $side-background;
                border-left-color: transparent;
            }
    
            &:after {
                content: "";
                display: block;
                width: 0;
                height: 0;
                position: absolute;
                top: 0;
                right: -30px;
                border: 15px solid transparent;
                border-left-color: $side-background;
            }
    
            &:first-of-type {
                @include rounded-corners(2px, 0, 0, 2px);
                padding-left: 15px;
    
                &:before {
                    display: none;
                }
            }
    
            &:last-of-type {
                @include rounded-corners(0, 2px, 2px 0);
                margin-right: 0;
                padding-right: 15px;
    
                &:after {
                    display: none;
                }
            }
    
            &.active {
                background: $ui-green-highlight;
                color: white;
    
                &:before {
                    border-color: $ui-green-highlight;
                    border-left-color: transparent;
                }
    
                &:after {
                    border-left-color: $ui-green-highlight;
                }
            }
    
            &.completed {
                background: rgba($side-background, 0.7);
                color: $font-color-light;
    
                &:before {
                    border-color: rgba($side-background, 0.7);
                    border-left-color: transparent;
                }
    
                &:after {
                    border-left-color: rgba($side-background, 0.7);
                }
            }
    
            &.error {
                background: $ui-red;
                color: $font-color-light;
    
                &:before {
                    border-color: $ui-red;
                    border-left-color: transparent;
                }
    
                &:after {
                    border-left-color: $ui-red;
                }
            }
    
            &.completed.error {
                background: rgba($ui-darker-red, 0.7);
                color: $font-color-light;
    
                &:before {
                    border-color: rgba($ui-darker-red, 0.7);
                    border-left-color: transparent;
                }
    
                &:after {
                    border-left-color: rgba($ui-darker-red, 0.7);
                }
            }
    
        }
    }
}
.step1, .step2, .step3{
    font-weight: 900;
    font-size: 16px;
    // padding:5px 0px;
    color: rgba(0, 0, 0, 0.88);
    margin: 10px 0px 2px;
    /* text-align: center; */
  }
  .custom-row td {
    padding:10px !important;
  }
  .uploadReport{
    min-width: 800px;
  }
  .custom-panel-header .ant-collapse-header {
    font-weight: bold;
    font-size: 16px;
    padding-top: 2px !important;
  }
  .preview{
    display: flex;
    padding-left: 15px;
    border-left: 1px solid gray;
    margin-left: 15px;
  }
  .previousReport{
    padding: 15px !important; ;
  }
  
  @media (max-width: 787px) {
    .uploadReport{
      min-width: auto;
    }
    .preview{
      border-left: none;
      margin-left:0px;
      padding-left: 0px;
    }
  }
  