.reports-panel {
  .panel-component {
    text-align: right;

    > div {
      text-align: left;
    }

    > button.more-reports-button {
      text-align: right;
      float: none;
      display: inline-block;
      margin-top: 1em;
    }
  }
}
