@import './variables';
@import './mixins';
@import './flexbox_mixin';

.app-content > .header {
    background-color: $header-background;
    @include flexbox();
    @include flex-direction(row);
    @include align-items(center);
    @include justify-content(center);

    .navigation-toggle {
        margin-left: 0.5em;
        font-size: 2em;
        line-height: 1.3em;
        cursor: pointer;
        @include transition(transform .5s);
    }

    .header-content {
        height: 100%;
        margin-left: 1em;
        z-index: 100;
        @include flex(2);
        @include flexbox();
        @include align-items(center);
        @include flex-direction(row);

        h2 {
            font-size: 1.6em;
            line-height: 1.2em;
            margin: 0;

            &.report-header {
                width: 100%;
            }

            
        }
        .report-header {
            span.header-details {
                color: $ui-blue-highlight;
                text-transform: uppercase;
            }

            span.header-details.blue {
                color: $ui-dark-blue;
            }

            span.header-details.orange {
                color: $ui-orange;
            }

            span.icon-close{
                cursor: pointer;
                float: right;
                margin-right: 1em;

                &:before {
                    content: "\f00d";
                    font-family: FontAwesome;
                }
            }
        }
    }

    .menu-wrapper {
        &.user-info {
            max-width: 10em;
            .menu-item {
                .menu-item-text {
                    overflow: hidden;
                }
                .menu-item-icon:before {
                    content: "\f007";
                }
            }
        }

        &.currency {
            max-width:5em;
            .menu-item {
                .menu-item-icon:before {
                    content: "\f0ec";
                }
            }
        }

        &.currency.differs {
            .menu-item {
                .menu-item-text {
                    background-color:$ui-dark-red;
                }
            }
        }


        &.language {
            max-width:4em;
            .menu-item {
                .menu-item-icon:before {
                    content: "\f1ab";
                }
            }
        }


    }
}