html, body {
  width:100%;
  height:100%;
  overflow: hidden;
}

button:focus {
  outline:none;
}
input:focus {
  outline:none;
}
a:focus {
  outline:none;
}
