
@keyframes startUpScaleIn {
    0% {
        /*-webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: grayscale(100%);*/
        opacity: 0;
        transform: scale(0);
    }
    100% {
       /* -webkit-filter: grayscale(0%);
        -moz-filter: grayscale(0%);
        -o-filter: grayscale(0%);
        filter: grayscale(0%);*/
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes startUpScaleOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    100% {
        transform: scale(10);
        opacity: 0;
    }
}

div.startup {
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    height:100%;

    .startup-logo {
        width:30%;
        height:30%;
        background-repeat:no-repeat;
        background-position: center center;
        background-size:contain;
        background-image: url(/img/logo-icon.svg);
        animation-name: startUpScaleIn;
        animation-iteration-count: 1;
        animation-timing-function: ease-out;
        animation-duration: 1s;

        &.fadeAway {
            transform: scale(0);
            opacity: 0;
            transition:transform 1s 0s,opacity 0.5s 0.5s;

        }
    }
}
