@import 'scss/mixins';
@import 'scss/flexbox_mixin';

.chart-container {
    margin-top: 3em;
    width: 100%;
    height: 20em;
    @include transition(height .2s);

    &.collapsed { height: 0; }
    &.small-container { height: 10em; }

    svg { overflow: visible !important; }

    .chart-wrapper {
        height: 100%;
        width: 100%;
        margin-top: 2em;
    }

    .chart-toggles {
        background-color: #535353;
        color: white;
        padding: .2em 1em;
        @include flexbox();
        @include justify-content(space-between);

        div.toggle-show-hide {
            cursor: pointer;
            span {
                margin-right: .5em;

                i { @include transition(transform .3s); }
                &.rotate i { @include transform(rotate(90deg)); }
            }
        }

        span.toggle-small-large {
            cursor: pointer;
        }
    }
}

.recharts-tooltip-wrapper {
    z-index: 999;
}
