@import 'scss/variables';
@import 'scss/flexbox_mixin';
@import 'scss/mixins';

div.investment-form {
    margin: .5em 0;

    div.investment-detail {
        @include flexbox();
        @include flex-wrap(wrap);
        @include flex-direction(row);
        @include justify-content(flex-start);

        div.investment-detail-item {
            @include flex(1);
            @include flexbox();
            @include flex-direction(column);
            @include flex-basis(15%);
            margin: .5em 1em;
            border-bottom: 1px solid $ui-light-grey;

            label {
                font-size: .8em;
            }

            div.display-field {
                text-transform: none;
                font-weight: bold;
                border-bottom: 0;
                font-size: 1em;
            }

            button[disabled] {
                @include opacity(.3);
                pointer-events: none;
            }

            div.Select {
                @include flex(5);
                text-align: right;
                background: $ui-soft-yellow;
                color: $ui-blue-highlight;

                &:focus {
                    outline: none;
                }

                div.Select-control {
                    -moz-border-radius: 0;
                    -webkit-border-radius: 0;
                    border-radius: 0;
                    border: none;
                    background: transparent;
                    color: $ui-white;

                    div.Select-value {
                        padding-right: 45px;
                        border: 1px solid $ui-blue-highlight;
                        background-color: $ui-blue-highlight;
                        span.Select-value-label {
                            color: $ui-white;
                        }
                        span.Select-value-icon {
                            border: none;
                            color: $ui-white;
                        }
                    }

                    div.Select-placeholder {
                        padding-right: 40px;
                    }
                }
            }
        }

        div.investment-detail-item.error {
            border-bottom: 1px solid $ui-red;

            label {
                color: $ui-red;
            }
        }

        div.investment-detail-item.editingPeriodicity {
            border-bottom: unset;
        }
    }

    div.investment-covenants {
        margin: .5em 1em;

        label {
            font-size: .8em;
        }
    }

    .cog-button  {
        cursor:pointer;
        padding-left: 5px;
        font-size:1.1em;
        color: #535353;
        text-shadow: 0px 0px 5px rgba(255, 255, 255, 1);
        &:hover {
            color: $ui-orange;
        }
    }
}
