@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/flexbox_mixin';

.o-label {
    color: $ui-white;
    padding: 0 0.5em;
    text-align: center;
    @include rounded-corners(4px);
    @include flexbox();
    @include justify-content(center);

    &--rejected {
        background: $ui-red;
    }

    &--overdue {
        background: $ui-red;
        color:white;
        font-weigth:bold;
        text-transform: uppercase;
        &:before {
            color:yellow;
            display: inline-block;
            content: "\f071";
            font-family: FontAwesome;
            width:2em;
        }
    }

    &--approved {
        background: $ui-green-highlight;
    }

    &--submitted {
        background: #f19836;
    }

    &--in_progress, &--in_review {
        background: $ui-dark-grey;
    }

    &--created {
        background: $ui-blue-highlight;
    }
}

.rc-table-inco {
    margin-top: 2em;
    table {
        margin-top: 2em;
        width: 100%;
        background: $ui-white;
        border-spacing: 0;
        font-size: .8rem;
        thead {
            th {
                position: relative;
                padding: 0.5rem;
                text-align: left;
                font-size: 1.1em;
                font-weight: bold;
                color: $ui-white !important;
                background-color: rgba($side-background,0.8) !important;
                border-left: 1px solid $ui-light-grey;
                border-top: 1px solid $ui-light-grey;

                &:last-child {
                    border-right: 1px solid $ui-light-grey;
                }
            }
        }
        tbody {
            tr {
                &.error td {
                    color: $ui-red;
                    font-weight: bold;
                }
                &:nth-child(even) td {
                    background-color: white
                }
                &.overdue td {

                }
            }
            td {
                padding: .5rem;

                &:not(.footer-container) {
                    border-left: 1px solid $ui-light-grey;
                    border-bottom: 1px solid $ui-light-grey;

                    &:last-child {
                        border-right: 1px solid $ui-light-grey;
                    }

                    button {
                        background: rgb(255, 253, 164);
                        color: rgb(0, 0, 64);
                        float: initial;
                        margin-left: .5em;
                        padding: 3px 10px;
                        font-size: .9em;
                        font-weight: bolder;
                        line-height: .8;
                        border: 1px solid red;
                        text-transform: none !important;
                        @include rounded-corners();
                        @include transition(all 0.2s ease-in);

                        &:hover {
                            background: $ui-blue-highlight;
                            color: $ui-white;
                            border-color: $ui-blue-highlight;
                        }
                    }
                }
            }

            &:last-child {
               // background: white;
            }
        }

       
    }

    &--clickable {
        table tbody tr {
            cursor: pointer;

            &:hover {
                background-color: transparentize($ui-soft-blue, .8) !important;
               
            }
            &:hover td {
                background-color: transparentize($ui-soft-blue, .8);
            }
        }
    }
}

.o-gridView {
    margin-top: 2em;

    &__table {
        margin-top: 2em;
        width: 100%;
        background: $ui-white;
        border-spacing: 0;
        font-size: .8rem;

        &-no-margin-top {
            margin-top: 0 !important;
        }

        thead {
            th {
                position: relative;
                padding: 0.5rem;
                text-align: left;
                font-size: 1.1em;
                font-weight: bold;
                color: $ui-white !important;
                background-color: rgba($side-background,0.8) !important;
                border-left: 1px solid $ui-light-grey;
                border-top: 1px solid $ui-light-grey;

                &:last-child {
                    border-right: 1px solid $ui-light-grey;
                }
            }
        }

        tbody {
            tr {
                &.error td {
                    color: $ui-red;
                    font-weight: bold;
                }
                &:nth-child(even) {
                    background-color: white
                }
                &.overdue td {

                }
            }
            td {
                padding: .5rem;

                &:not(.footer-container) {
                    border-left: 1px solid $ui-light-grey;
                    border-bottom: 1px solid $ui-light-grey;

                    &:last-child {
                        border-right: 1px solid $ui-light-grey;
                    }

                    button {
                        background: rgb(255, 253, 164);
                        color: rgb(0, 0, 64);
                        float: initial;
                        margin-left: .5em;
                        padding: 3px 10px;
                        font-size: .9em;
                        font-weight: bolder;
                        line-height: .8;
                        border: 1px solid red;
                        text-transform: none !important;
                        @include rounded-corners();
                        @include transition(all 0.2s ease-in);

                        &:hover {
                            background: $ui-blue-highlight;
                            color: $ui-white;
                            border-color: $ui-blue-highlight;
                        }
                    }
                }
            }

            &:last-child {
                background: white;
            }
        }

        &--clickable {
            tbody tr {
                cursor: pointer;

                &:hover {
                    background-color: transparentize($ui-soft-blue, .8);
                }
            }
        }

        &--paged {
            tbody:last-of-type tr {
                cursor: default;

                &:hover {
                    background-color: inherit !important;
                }
            }
        }

        &--sortable {
            th {
                cursor: pointer;

                &:hover {
                    background: $side-background !important;
                }

                &.sort-ascending,
                &.sort-descending {
                    color: $ui-blue-highlight !important;
                }

                &.sort-ascending > .fa {
                    position: absolute;
                    right: 1em;
                    top: .9em;
                }

                &.sort-descending > .fa {
                    position: absolute;
                    right: 1em;
                    bottom: 1em;
                }
            }
        }
    }
}

.filter-wrapper {
    font-size: 1rem;
    margin:5px;
    margin-top: 2em;
    border-bottom: 1px solid $ui-blue-highlight;
    width: 30%;

    input {
        color: $ui-blue-highlight;
        width: 100%;
        font-size: 1rem;
        border-width: 0;
        background: transparent;
        @include transition(border-bottom-color 0.2s ease-in);

        &:focus {
            outline: none;
        }

        @include input-placeholder() {
            color: $ui-blue-highlight;
            font-size: 1em;
            opacity: 0.5;
            letter-spacing: normal;
            font-weight: normal;
        }
    }
}
.range-wrapper {
    color: $ui-blue-highlight;
    font-size: 0.9em;
    margin-top: 1em;
    width: 100%;
    @include flexbox();
    @include flex-direction(row);

    div.date-field {
        border-bottom: 1px solid $ui-blue-highlight;
        width: auto;
        padding: 0 1%;
        margin: 0 .5em;

        input { text-align: center; }
    }
}

.griddle {
    .griddle-filter {
        font-size: 0.9em;
        border-bottom: 1px solid $ui-blue-highlight;
        width: 30%;

        input {
            color: $ui-blue-highlight;
            width: 100%;
            border-width: 0;
            background: transparent;
            @include transition(border-bottom-color 0.2s ease-in);

            &:focus {
                outline: none;
            }

            @include input-placeholder() {
                color: $ui-blue-highlight;
                font-size: 1em;
                opacity: 0.5;
                letter-spacing: normal;
                font-weight: normal;
            }
        }
    }

    .griddle-footer {
        position: relative;
        text-align: center;

        div {
            @include flexbox();
            @include flex-direction(row);
            @include justify-content(center);
            @include align-items(center);
        }

        .griddle-previous {
            position: absolute;
            left: 0;
            top: 0;
        }

        .griddle-page {
            margin-top: 0 !important;
        }

        .griddle-next {
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}

.pagination-container {
    @include flexbox();
    @include flex-direction(row);
    @include align-items(center);
    height: 5em;
    width: 100%;

    div {
        width: 33%;
        text-align: center;

        button {
            float: none !important;

            &.page-selected {
                background: $ui-blue-highlight;
            }
        }
    }
}
