// -------------------------------------------------------------------
// :: TYPOGRAPHY
// -------------------------------------------------------------------

@import 'fa/font-awesome';
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import 'texgy/font';

@font-face {
    font-family: 'AvantGardeGothicITCW01Bd';
    src: url('/fonts/AvantGardeGothicITCW01Bd.eot');
    src: url('https://fast.fonts.net/dv2/3/e9a79553-7093-47c7-8d9c-70dc3619e94b.woff?d44f19a684109620e4841578a190e81899ea4ef258eef72d3a036e96543ce79f6a8dbdb4030c201d44f5a4bc228df0de57f0fa4ba0f947180304a98ae97f713c69a224f0dec7bf8c63b55256720a0558b197f96a02745a3a404aae858e743b135212e8b4bdf81233b828eb93bd1f25a197d8bd55&projectId=662fac8a-84c8-4a9f-aed3-9d01f1d1b059') format('woff2'),
    url('https://fast.fonts.net/dv2/3/e9a79553-7093-47c7-8d9c-70dc3619e94b.woff?d44f19a684109620e4841578a190e81899ea4ef258eef72d3a036e96543ce79f6a8dbdb4030c201d44f5a4bc228df0de57f0fa4ba0f947180304a98ae97f713c69a224f0dec7bf8c63b55256720a0558b197f96a02745a3a404aae858e743b135212e8b4bdf81233b828eb93bd1f25a197d8bd55&projectId=662fac8a-84c8-4a9f-aed3-9d01f1d1b059') format('woff'),
    url('/fonts/AvantGardeGothicITCW01Bd.ttf') format('truetype'),
    url('/fonts/AvantGardeGothicITCW01Bd.svg#AvantGardeGothicITCW01Bd') format('svg'),
    url('/fonts/AvantGardeGothicITCW01Bd.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family:"AvantGardeGothicITCW01B 731063";
    src:url("https://fast.fonts.net/dv2/3/e9a79553-7093-47c7-8d9c-70dc3619e94b.woff?d44f19a684109620e4841578a190e81899ea4ef258eef72d3a036e96543ce79f6a8dbdb4030c201d44f5a4bc228df0de57f0fa4ba0f947180304a98ae97f713c69a224f0dec7bf8c63b55256720a0558b197f96a02745a3a404aae858e743b135212e8b4bdf81233b828eb93bd1f25a197d8bd55&projectId=662fac8a-84c8-4a9f-aed3-9d01f1d1b059") format('woff');
}

html {
    font-size: 12px;

    @include respond-to('small') {
        font-size: 12px;
    }

    @include respond-to('medium') {
        font-size: 14px;
    }

    @include respond-to('x-large') {
        font-size: 14px;
    }

    @include respond-to('xx-large') {
        font-size: 20px;
    }

    @include respond-to('xxx-large') {
        font-size: 23px;
    }
}

body {
    color: $font-color;
    font-family: $font-family-sans;
    font-size: rem($font-size);
    line-height: $line-height;
}


h1 {
    color:$dark;
    font-size:2em;
    font-family: $font-family-title;
    line-height: 1em;
}

h2 {
    color:$dark;
    font-size:1.6em;
    font-family: $font-family-title;
    line-height: 1.5em;
    overflow: hidden;
}

p {
    color: $light-extra;
    font-family: $font-family-sans;
    font-weight: 300;
    text-align: center;
    font-size: 0.9em;
}