@import 'scss/variables';
@import 'scss/flexbox_mixin';
@import 'scss/mixins';

.ant-input-number-group-wrapper {

    max-width:100%;
    .ant-input-number-group {
        display: inline-flex;
        align-items: stretch;
        justify-content: center;
        .ant-input-number {
            display: block;
            flex:1;
            min-width: 0;
        }

        .ant-input-number-group-addon {
            display: block;
            min-width: fit-content;
            line-height: 200%;
            font-size: 0.7em;
        }
    }
}


$padding: 0.3rem;

@mixin level1() {
    background: $ui-dark-blue;
    font-weight: bold;
    text-transform: uppercase;
    color: $font-color-light;
}
@mixin level2() {
   
    color: $ui-white;
    background: $ui-soft-blue;
    font-weight: bold;
    text-transform: uppercase;
}
@mixin level2Agro() {
    color: $ui-white;
    background: $ui-green-highlight;
    font-weight: bold;
    text-transform: uppercase;
    
}
@mixin level3() {
    //padding-left: $padding*2;
    color: $ui-dark-grey;
    background: rgb(242, 242, 242);
    text-transform: uppercase;
    font-weight: normal;
    
}
@mixin level4() {
    //padding-left: $padding*2.5;
    color: $ui-dark-grey;
    background: white;
    text-transform: uppercase;
    font-weight: normal;
    font-style: italic;
}

@mixin level_last() {
    //padding-left: $padding*2;
    color: $ui-dark-grey !important;
    background:white !important;
    text-transform: uppercase;
    font-weight: normal !important;
    font-style: normal !important;
}

@mixin has-errors {
    background: $ui-error !important;
    color:$ui-off-white !important;
}

@mixin has-warnings {
    background: $ui-orange !important;
    color:$ui-off-white !important;
}

.formula-icon {
    //background:"gray";
   // color:"white";
    //padding:1px;
    margin:2px;
    width:12px;
    height: 12px;
    margin-left:5px;

    &:hover {
        cursor: pointer;
        background-color: $ui-dark-blue;
        color:$ui-white;
    }
}

.formula-link {
    
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.field-label {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    
    .field-label-fx {

        width:19px;
        height: 16px;
    }
}

.field-value-error {
    color:$ui-red;
}

.form-tree {
    display: flex;
    flex-direction: row;

    

    .field-label-wrap {
        .field-label {

        }
       
    }

    .form-tree-edit-line {
        display: flex;
        flex-direction: row;
    }

    .form-tree-column-header {
        background:$ui-orange;
        color:$ui-white;
        min-height: 6em;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 5px;
    }

    .form-tree-label-column {
        .form-tree-column-header {

            background:unset;
            color:$ui-white;
        }
    }       
    .form-tree-columnAgro {
        .form-tree-field-line {
            flex: 1 1;
            white-space: nowrap;
            padding: .5rem 4px .5rem 1rem;
            border-bottom: 1px solid $ui-grey;
            height: 2.8em;
            width: 100%;
            font-size: .8em;

            padding-right: 1rem;
            padding-left: 1rem;

            @include flexbox();
            @include flex-direction(row);
            //@include transition(all .2s ease-out);
            @include justify-content(left);

            svg {
                min-width: 12px;
            }

            
        }

        &.form-tree-data-column {
            max-width: fit-content;
            .form-tree-field-line {
                @include justify-content(right);
            }
        }

        &.form-tree-field-column {
            max-width: fit-content;
            .form-tree-field-line {
                @include justify-content(right);
            }
        }

        & > .form-tree-field-group {
            & > .form-tree-field-line {
                @include level1();
                .field-label-text {
                    
                }
                
            }
            //  -------------Field Padding--------------------------------
            & > .form-tree-field-group-content {
                & > .form-tree-field-line  {
                    padding-left: 60px;
                }
            }
            & > .form-tree-field-group-content{ & > .form-tree-field-group{ & > .form-tree-field-group-content{ & > .form-tree-field-line{
                padding-left: 60px;
            }}}}
            // ----------------------------------------------------------------
            & > .form-tree-field-group-content {
                & > .form-tree-field-group {
                    & > .form-tree-field-line  {
                        @include level2Agro();
                        .field-label-text {
                            padding-left: $padding*.5;
                        }
                    }
                    & > .form-tree-field-group-content {

                        & > .form-tree-field-group {
                            & > .form-tree-field-line  {
                                @include level3();
                                .ffield-label-text {
                                    padding-left: $padding*2;
                                }
                            }
                            // ---------------Field Padding------------------------------
                                & > .form-tree-field-group-content {
                                    & > .form-tree-field-line  {
                                        padding-left: 60px;
                                    }
                                }
                                & > .form-tree-field-group-content{ & > .form-tree-field-group{ & > .form-tree-field-group-content{ & > .form-tree-field-line{
                                    padding-left: 60px;
                                }}}}
                            & > .form-tree-field-group-content {

                                & > .form-tree-field-group {
                                    & > .form-tree-field-line  {
                                        @include level4();
                                        .field-label-text {
                                            padding-left: $padding*2.5;
                                        }
                                    }
                        
                                    & > .form-tree-field-group-content {
                        
                                        
                                    }
                                }
                                & > .form-tree-field-line  {
                                    @include level4();
                                    .field-label-text {
                                        padding-left: $padding*2.5;
                                    }
                                }
                
                            }
                        }
                
                        & > .form-tree-field-line  {
                            @include level_last();
                            .field-label-text {
                                padding-left: $padding*2;
                            }
                        }
        
                    }
                }
        
                & > .form-tree-field-line {
                    @include level_last();
                    .field-label-text {
                        padding-left: $padding*2;
                    }
                }

            }
        }

        // & > .form-tree-field-line {
        //     @include level2Agro();
        //     .field-label-text {
        //         padding-left: $padding;
        //     }
        // }
       
    }
    .form-tree-columnAgro {
        .form-tree-field-lineAgros {
            flex: 1 1;
            white-space: nowrap;
            padding: .5rem 4px .5rem 1rem;
            border-bottom: 1px solid $ui-grey;
            height: 2.8em;
            width: 100%;
            font-size: .8em;

            padding-right: 1rem;
            padding-left: 1rem;

            @include flexbox();
            @include flex-direction(row);
            //@include transition(all .2s ease-out);
            @include justify-content(left);

            svg {
                min-width: 12px;
            }
        }
        &.form-tree-data-column {
            max-width: fit-content;
            .form-tree-field-lineAgros {
                @include justify-content(right);
            }
        }

        &.form-tree-field-column {
            max-width: fit-content;
            .form-tree-field-lineAgros {
                @include justify-content(right);
            }
        }
        & > .form-tree-field-group {
            & > .form-tree-field-lineAgros {
                @include level1();
                .field-label-text {
                    
                }
            }
            // ----------------------------------------------------------------
            & > .form-tree-field-group-content {
                & > .form-tree-field-group {
                    & > .form-tree-field-lineAgros  {
                        @include level2();
                        .field-label-text {
                            padding-left: $padding*.5;
                        }
                    }
                    & > .form-tree-field-group-content {

                        & > .form-tree-field-group {
                            & > .form-tree-field-lineAgros  {
                                @include level3();
                                .ffield-label-text {
                                    padding-left: $padding*2;
                                }
                            }
                
                            & > .form-tree-field-group-content {

                                & > .form-tree-field-group {
                                    & > .form-tree-field-line  {
                                        @include level4();
                                        .field-label-text {
                                            padding-left: $padding*2.5;
                                        }
                                    }
                        
                                    & > .form-tree-field-group-content {
                        
                                        
                                    }
                                }
                        
                                & > .form-tree-field-line  {
                                    @include level4();
                                    .field-label-text {
                                        padding-left: $padding*2.5;
                                    }
                                }
                
                            }
                        }
                
                        & > .form-tree-field-line  {
                            @include level_last();
                            .field-label-text {
                                padding-left: $padding*2;
                            }
                        }
        
                    }
                }
        
                & > .form-tree-field-line {
                    @include level_last();
                    .field-label-text {
                        padding-left: $padding*2;
                    }
                }

            }
        }

        & > .form-tree-field-line {
            @include level2();
            .field-label-text {
                padding-left: $padding;
            }
        }
       
    }
    .form-tree-columnAgroValue {
        .form-tree-field-lineAgros {
            flex: 1 1;
            white-space: nowrap;
            padding: .5rem 4px .5rem 1rem;
            border-bottom: 1px solid $ui-grey;
            height: 2.8em;
            width: 100%;
            font-size: .8em;

            padding-right: 1rem;
            padding-left: 1rem;

            @include flexbox();
            @include flex-direction(row);
            //@include transition(all .2s ease-out);
            @include justify-content(left);

            svg {
                min-width: 12px;
            }
        }
        &.form-tree-data-column {
            max-width: fit-content;
            .form-tree-field-lineAgros {
                @include justify-content(right);
            }
        }

        &.form-tree-field-column {
            max-width: fit-content;
            .form-tree-field-lineAgros {
                @include justify-content(right);
            }
        }
        & > .form-tree-field-group {
            & > .form-tree-field-lineAgros {
                @include level1();
                .field-label-text {
                    
                }
            }
            // ----------------------------------------------------------------
            & > .form-tree-field-group-content {
                & > .form-tree-field-group {
                    & > .form-tree-field-lineAgros  {
                        @include level2();
                        .field-label-text {
                            padding-left: $padding*.5;
                        }
                    }
                    & > .form-tree-field-group-content {

                        & > .form-tree-field-group {
                            & > .form-tree-field-lineAgros  {
                                @include level3();
                                .ffield-label-text {
                                    padding-left: $padding*2;
                                }
                            }
                
                            & > .form-tree-field-group-content {

                                & > .form-tree-field-group {
                                    & > .form-tree-field-line  {
                                        @include level4();
                                        .field-label-text {
                                            padding-left: $padding*2.5;
                                        }
                                    }
                        
                                    & > .form-tree-field-group-content {
                        
                                        
                                    }
                                }
                        
                                & > .form-tree-field-line  {
                                    @include level4();
                                    .field-label-text {
                                        padding-left: $padding*2.5;
                                    }
                                }
                
                            }
                        }
                
                        & > .form-tree-field-line  {
                            @include level_last();
                            .field-label-text {
                                padding-left: $padding*2;
                            }
                        }
        
                    }
                }
        
                & > .form-tree-field-line {
                    @include level_last();
                    .field-label-text {
                        padding-left: $padding*2;
                    }
                }

            }
        }

        & > .form-tree-field-line {
            @include level2();
            .field-label-text {
                padding-left: $padding;
            }
        }
    
    }
    .form-tree-columnAgroValue {
        .form-tree-field-line {
            flex: 1 1;
            white-space: nowrap;
            padding: .5rem 4px .5rem 1rem;
            border-bottom: 1px solid $ui-grey;
            height: 2.8em;
            width: 100%;
            font-size: .8em;

            padding-right: 1rem;
            padding-left: 1rem;

            @include flexbox();
            @include flex-direction(row);
            //@include transition(all .2s ease-out);
            @include justify-content(left);

            svg {
                min-width: 12px;
            }
        }
        & > .form-tree-field-group {
            & > .form-tree-field-line {
                @include level1();
                .field-label-text {
                    
                }
            }
            // ----------------------------------------------------------------
            & > .form-tree-field-group-content {
                & > .form-tree-field-group {
                    & > .form-tree-field-line  {
                        @include level2Agro();
                        .field-label-text {
                            padding-left: $padding*.5;
                        }
                    }
                }

            }
        }
    }

    .form-tree-column {
        .form-tree-field-line {
            flex: 1 1;
            white-space: nowrap;
            //padding: .5rem 4px .5rem 1rem;
            border-bottom: 1px solid $ui-grey;
            height: 2.8em;
            width: 100%;
            font-size: .8em;

            padding-right: 1rem;
            padding-left: 1rem;

            @include flexbox();
            @include flex-direction(row);
            //@include transition(all .2s ease-out);
            @include justify-content(left);

            svg {
                min-width: 12px;
            }

            
        }

        &.form-tree-data-column {
            max-width: fit-content;
            .form-tree-field-line {
                @include justify-content(right);
            }
        }

        &.form-tree-field-column {
            max-width: fit-content;
            .form-tree-field-line {
                @include justify-content(right);
            }
        }

        & > .form-tree-field-group {
            & > .form-tree-field-line {
                @include level1();
                .field-label-text {

                }
                
            }

            & > .form-tree-field-group-content {

                & > .form-tree-field-group {
                    & > .form-tree-field-line  {
                        @include level2();
                        .field-label-text {
                            padding-left: $padding*1.5;
                        }
                    }
        
                    & > .form-tree-field-group-content {

                        & > .form-tree-field-group {
                            & > .form-tree-field-line  {
                                @include level3();
                                .ffield-label-text {
                                    padding-left: $padding*2;
                                }
                            }
                
                            & > .form-tree-field-group-content {

                                & > .form-tree-field-group {
                                    & > .form-tree-field-line  {
                                        @include level4();
                                        .field-label-text {
                                            padding-left: $padding*2.5;
                                        }
                                    }
                        
                                    & > .form-tree-field-group-content {
                        
                                        
                                    }
                                }
                        
                                & > .form-tree-field-line  {
                                    @include level4();
                                    .field-label-text {
                                        padding-left: $padding*2.5;
                                    }
                                }
                
                            }
                        }
                
                        & > .form-tree-field-line  {
                            @include level_last();
                            .field-label-text {
                                padding-left: $padding*2;
                            }
                        }
        
                    }
                }
        
                & > .form-tree-field-line {
                    @include level_last();
                    .field-label-text {
                        padding-left: $padding*2;
                    }
                }

            }
        }

        & > .form-tree-field-line {
            @include level1();
            .field-label-text {
                padding-left: $padding;
            }
        }

  /* &:not(:has(.form-tree-field-group-content)) {
                    .form-tree-field-line {
                        @include level_last()
                    }
                }*/
       
    }

    .form-tree-label-column {
        text-align: left;

        .form-tree-field-line {
            overflow-x: hidden;
overflow-y: hidden;
text-overflow:ellipsis
        }

    }
    .form-tree-data-column {
        text-align: right;
    }

    .form-tree-fixed {
        max-width:50%;
        flex:1;
        border-right: 2px solid $ui-dark-grey;

    }

    .form-tree-editor {
        flex:1;
        display: flex;
        flex-direction: row;
        .form-tree-label-column {
            width: fit-content;
        }
        .form-tree-field-column {
            width: fit-content;
            text-align: right;

            .form-tree-field-line {
                padding-right: 0px;
            }

            .ant-input-number, .ant-input {
                min-width:9em; 
                //180px;
            }
            .field-entry {
                display: flex;
                justify-content: center;
                flex-direction: column;
            }

            input {
               
                height: 1em;
                text-align: right;

                font-size: 12px;
            }

            .ant-input-number-group-addon {
                .ant-tag {
                    height: 20px;
                    font-size: 9px;
                }

                .ant-tag.scale {
                    height: 20px;
                    font-size: 8px;
                }
            }
           

            .field-display-value {
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
                font-size: 12px;
                padding-right: 1em;
            }
        }
    }

    .form-tree-data {
        flex: 2 1;
        
        display: flex;
        flex-direction: row;
        overflow-x: scroll;

        .form-tree-column {
            flex:1 1;
            margin-right:1px;
        }

    }
} 

.form-flat {
    font-size: 0.8rem;
    //flex:1;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    border-left:1px solid $ui-dark-blue;

    .form-flat-caption {
       
        margin-bottom: 10px;
    }

    .form-flat-field-line-group, .form-flat-heading {
        
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: start;

      

        &.form-flat-level-1 {
            @include level1();
            &.field-has-errors {
                .form-flat-label-column, .form-flat-value-column:not(.form-flat-extra-period-column) {
                    @include has-errors();
                }
               
            }
        }
        &.form-flat-level-2 {
            @include level2();
            .field-label-text {
                padding-left: $padding*1.5;
            }
            &.field-has-errors {
                .form-flat-label-column, .form-flat-value-column:not(.form-flat-extra-period-column) {
                    @include has-errors();
                }
               
            }
        }
        &.form-flat-level-3 {
            @include level3();
            .field-label-text {
                padding-left: $padding*2;
            }
            &.field-has-errors {
                .form-flat-label-column, .form-flat-value-column:not(.form-flat-extra-period-column) {
                    @include has-errors();
                }
               
            }
        }
        &.form-flat-level-4 {
            @include level4();
            .field-label-text {
                padding-left: $padding*2.5;
            }
            &.field-has-errors {
                .form-flat-label-column, .form-flat-value-column:not(.form-flat-extra-period-column) {
                    @include has-errors();
                }
               
            }
        }

        &.form-flat-level-last {
            @include level_last();
            .field-label-text {
                padding-left: $padding*2;
            }

            &.field-breached {
                .form-flat-label-column, .form-flat-value-column:not(.form-flat-extra-period-column) {
                   // background: $ui-soft-red !important;
                
                    color:$ui-error!important;
                }
            }

            &.field-has-errors {
                .form-flat-label-column, .form-flat-value-column:not(.form-flat-extra-period-column) {
                    @include has-errors();
                }
               
            }


        }
       
    }

    /*
    .form-flat-field-line-group.field-has-errors {
         
            &.form-flat-level-1 {
                background: $ui-red;
                color:$ui-off-white;
            }
            &.form-flat-level-2 {
                background: $ui-red;
                color:$ui-off-white;
            }
            &.form-flat-level-3 {
                background: $ui-red;
                color:$ui-off-white;
            }
            &.form-flat-level-4 {
                background: $ui-red;
                color:$ui-off-white;
            }
    
            &.form-flat-level-last {
                background: $ui-red;
                color:$ui-off-white;
            }
        
        
    }
    .form-flat-field-line-group.field-has-warnings {
        
            &.form-flat-level-1 {
                background: $ui-orange;
                color:$ui-off-white;
            }
            &.form-flat-level-2 {
                background: $ui-orange;
                color:$ui-off-white;
            }
            &.form-flat-level-3 {
                background: $ui-orange;
                color:$ui-off-white;
            }
            &.form-flat-level-4 {
                background: $ui-orange;
                color:$ui-off-white;
            }
    
            &.form-flat-level-last {
                background: $ui-orange;
                color:$ui-off-white;
            }
    
    }
*/
    .form-flat-cell {
        padding-bottom: 0.5em;
        padding-top: 0.5em;
    }

    .form-flat-cell.form-flat-label-column {
        flex: 1;
    }

    .form-flat-cell.form-flat-value-column {
        width: 15rem;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        text-align: right;
        min-width: 12rem;

        .field-value {
            text-align: right;
            padding-right: 1em;
        }
    }

    .form-flat-cell.form-flat-spacer-column {
        width:1em;
        background-color: white;
        border-left: 1px solid $ui-dark-blue;
        

    }
    .form-flat-cell.form-flat-extra-period-column {
        opacity: 0.5;
        border-left: 1px dashed $ui-dark-blue;
        border-right: 1px dashed $ui-dark-blue;
    }

    .form-flat-heading {
        background-color: $side-background;
        color:$ui-white;
        font-size: 1.4em;
        margin-bottom: 5px;
        position: sticky;
        top: 0px;
        z-index: 999;
        .form-flat-cell {
        text-align: center !important ;
        }
    }
    .ant-input-number, .ant-input {
        min-width:9rem;
        //min-width: 180px;
        input {
            font-size: 1rem;
        text-align: right;
        }
    }
    .field-entry {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: end;
    }

    .field-display-value {
        padding-right: 1em;
    }

}
/*
.form-flat {
    //display: table;

    .field-label {
        padding-right: 2em;
    }

    .form-flat-field-line-group {
        display: table-row-group;
    }

    .form-flat-field-formula-content {
        column-span: all;
       
    }
    
    .show-formula {
        .form-flat-field-formula {
            display: table-row;
        }
    }

    .form-flat-field-line {
        display: table-row;
        &.form-flat-field-formula {
            display: none;
        }

        white-space: nowrap;
        &.form-flat-field-formula {
            white-space: wrap;
        }
        //padding: .5rem 4px .5rem 1rem;
        border-bottom: 1px solid $ui-grey;
        min-height: 2.8em;
        width: 100%;
        font-size: .8em;

        padding-right: 1rem;
        padding-left: 1rem;

        svg {
            min-width: 12px;
        }

        &.form-flat-level-1 {
            @include level1();
        }
        &.form-flat-level-2 {
            @include level2();
            .field-label-text {
                padding-left: $padding*1.5;
            }
        }
        &.form-flat-level-3 {
            @include level3();
            .field-label-text {
                padding-left: $padding*2;
            }
        }
        &.form-flat-level-4 {
            @include level4();
            .field-label-text {
                padding-left: $padding*2.5;
            }
        }

        &.form-flat-level-last {
            @include level_last();
            .field-label-text {
                padding-left: $padding*2;
            }
        }



        .form-flat-cell {
            display: table-cell;
            min-width: fit-content;
            vertical-align: middle;

        }

        .form-flat-label-column {
            text-align: left;
        }

        .form-flat-value-column {
            text-align: right;
            min-width: 150px;
        }

        .ant-input-number, .ant-input {
            min-width: 180px;
        }
        .field-entry {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: end;
        }

        .field-display-value {
            padding-right: 1em;
        }

        input {
           
            height: 1em;
            text-align: right;

            font-size: 12px;
        }

        .ant-input-number-group-addon {
            .ant-tag {
                height: 20px;
                font-size: 9px;
            }

            .ant-tag.scale {
                height: 20px;
                font-size: 8px;
            }
        }
    }

    .form-flat-field-line-group {
        &.field-has-errors {
            .form-flat-field-line {
                &.form-flat-level-1 {
                    background: $ui-red;
                    color:$ui-off-white;
                }
                &.form-flat-level-2 {
                    background: $ui-red;
                    color:$ui-off-white;
                }
                &.form-flat-level-3 {
                    background: $ui-red;
                    color:$ui-off-white;
                }
                &.form-flat-level-4 {
                    background: $ui-red;
                    color:$ui-off-white;
                }
        
                &.form-flat-level-last {
                    background: $ui-red;
                    color:$ui-off-white;
                }
            }
            
        }
        &.field-has-warnings {
            .form-flat-field-line {
                &.form-flat-level-1 {
                    background: $ui-orange;
                    color:$ui-off-white;
                }
                &.form-flat-level-2 {
                    background: $ui-orange;
                    color:$ui-off-white;
                }
                &.form-flat-level-3 {
                    background: $ui-orange;
                    color:$ui-off-white;
                }
                &.form-flat-level-4 {
                    background: $ui-orange;
                    color:$ui-off-white;
                }
        
                &.form-flat-level-last {
                    background: $ui-orange;
                    color:$ui-off-white;
                }
            }
            
        }
    }
   


}
*/