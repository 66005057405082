@import 'scss/variables';

.period-select-body {
   // overflow: hidden;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    background-color: white;

    
}

.period-select-year {
    display: inline-flex;
    flex-direction: column;
    margin-left: 1px;
    margin-right: 1px;
    border:0px solid black;
    border-left-width: 1px;
        border-right-width: 1px;

    .period-select-year-header {
        text-align: center;
        border:0px solid black;
        border-bottom-width: 1px;
        border-top-width: 1px;

        background-color: rgba(147, 186, 54, 0.3);
        font-weight: bold;

        

    }
    .period-select-year-body {
        display: inline-grid;
        border:0px solid black;
        
        margin: 0;
        padding: 0;
        text-align: center;
        grid-gap: 0px;
        justify-items: stretch;
        align-items: center;
        justify-content: center;
        align-content: center;
        grid-auto-flow: column;
        grid-template-rows: repeat(3, 1fr);

        abbr,span  {
            user-select: none;
            display: inline-block;
            padding:2px 4px;
            border:0px solid black;
            border-bottom-width: 1px;
            border-left-width:1px;
            text-transform: uppercase;
            font-size: 0.8em;
            height: 100%;

            &.selected {
                background: $ui-green-highlight !important;;
                color: $ui-white !important;

            }

            &:not(.period-select-spacer) {
                background-color:  $ui-soft-yellow;
            }
        
           /* &:nth-child(3n+3) {
                border-bottom-width: 1px;
                //background-color: yellow;
            }
        
            &:nth-child(-n+3) {
                border-left-width: 0px;
            }
        
            &:nth-child(4) {
               
            }*/
        
            &[data-year] {
                display: block;
                grid-row: span 3;
                writing-mode: vertical-rl;
                text-orientation: upright;
                height: 100%;

                padding: 0px;
              //  font-size: 0.6em;
                font-weight: 700;
                background-color: rgba(147, 186, 54, 0.3);
            }
           
            &.approved {
                color:$ui-white;
                background-color: $status-approved;
            }
            &.rejected {
                color:$ui-white;
                background-color: $status-rejected;
            }
            &.submitted {
                color:$ui-white;
                background-color: $status-submitted;
            }
            &.in_progress {
                color:$ui-white;
                background-color: $status-in_progress;
            }
            &.in_review {
                color:$ui-white;
                background-color: $status-in_review;
            }

            &.unselectable {
                opacity: 0.5;
                background-color: $ui-grey !important;
            }


            &.selected {
                background-color: $ui-blue-highlight !important;
                color: $ui-white !important;

            }

            &:not([data-year], .unselectable):hover {
                background-color: $ui-blue-highlight !important;
                color: $ui-white !important;
                cursor: pointer;
            }
           
        }
    }
}

.period-select-financial {
    .period-select-year-header {
     background-color: rgba(123, 172, 203, 0.5);
    }
}

.period-body {
    overflow-y: scroll;
    position:relative;
}

.my-grid {
    display: inline-grid;
    position:relative;
    /* grid-template-columns: repeat(4, 1fr); */
    list-style: none;
   
    //border-top:1px solid black;

    &.financial {
        position: absolute;
        top:0px;
        left:0px;
    }
}

.year-header {
   //display: ;
   
}


