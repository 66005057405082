// summary-list
@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/flexbox_mixin';

.c-summary-list {
  margin: .5rem 0;
  font-size: .8rem;

  &__items-container {
    @include flexbox();
    @include flex-flow(row wrap);
  }
  &__item {
    @include flex(0, 0);
    padding: 0 1rem 0 1.5rem;

    &:first-child {
      padding-left: 0;
    }

    &:not(:last-child) {
      border-right: 1px solid $summary-border-color;
    }
  }
}

.c-summary-list--scrollable {
  position: relative;

  .o-scrollable-wrapper {
    margin: 0 1rem;
    position: relative;
    overflow: hidden;

    .c-summary-list__items-container {
      @include flex-wrap(nowrap);
      @include flex(1);
      @include transition(left 1s cubic-bezier(0.175, 0.885, 0.32, 1.275));
      position: relative;
    }
    .c-summary-list__item {
      @include flex(1, 0, auto);
    }
  }

  &.c-summary-list--has-buttons {
    .o-scrollable-wrapper {
      margin: 0 2.1rem;
    }

    .o-btn {
      position: absolute;
      top: 1rem;
      width: 2rem;
      height: 2rem;
      border-radius: 2rem;
      border: 1px solid $summary-border-color;
      font-size: 1rem;
      line-height: 1;
      padding:0;
      background: none;
      color: $summary-border-color;
      @include opacity(.6);
      @include transition(all .5s ease-in);

      &:hover {
        @include opacity(1);
        background: transparentize($summary-border-color, .8);
      }
      &[disabled] {
        @include opacity(.1);
      }

      &--prev {
        left: 0;
        @include box-shadow(7px 0px 15px -5px transparentize(darken($summary-border-color, 50%), .6));
      }
      &--next {
        right: 0;
        @include box-shadow(-7px 0px 15px -5px transparentize(darken($summary-border-color, 50%), .6));
      }
    }
  }
}
