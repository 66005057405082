

.card {

    background-color: white;

    .card-head { 
        min-height: 48px;
        margin-bottom: -1px;
        padding: 0 24px;
        color: #000000d9;
        font-weight: 500;
        font-size: 16px;
        background: 0 0;
        border-bottom: 1px solid rgba(0,0,0,.06);
        border-radius: 10px 10px 0 0;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: "tnum";


        .card-head-wrapper {
            display: flex;
            align-items: center;
            .card-title {
                color: #000000d9;
                font-weight: 500;
                font-size: 16px;
                display: inline-block;
                flex: 1;
                padding: 16px 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        
    }

    .card-body {
        padding: 24px;
    }
}