@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/flexbox_mixin';

.export-form {
  height: 100%;
  overflow: visible;

  .Select--multi .Select-value {
    background-color: $ui-blue-highlight;
    border: $ui-blue-highlight;
  }

  div.Select {
    div.Select-control {
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0;
      border: none;
      color: $ui-white !important;

      div.Select-value {
        color: $ui-blue-highlight !important;
        span.Select-value-icon {
          border: none;
        }
      }
    }
  }

  .Select {
    height: 100%;
    overflow: visible;
  }

  .clients-grid-select {
    display: inherit;
    height: 28em;

    .inco-table {
      margin-top: 1em;
      width: 50%;
    }
    .rc-table  {
     
    }

    .griddle-body {
      > div {
        overflow-y: scroll;
        height: 25em;
      }
    }

    th {
      color: $ui-white !important;
    }

    .griddle:nth-child(2) {
      th {
        background-color: $ui-blue-highlight !important;
      }
    }
  }

  button {
    float: left;
    background-color:rgba(147,147,147,0.4);



    &.selected {
      background-color: $ui-blue-highlight;
    }
  }

  .submit-button {
    button {
      background-color: $ui-green-highlight;
    }
  }

  .submit-button {
    float: right;
  }
}
