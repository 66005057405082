@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/flexbox_mixin';

.timeslicer {
    margin-bottom: 2em;
    @include flexbox();
    @include flex-direction(column);

    .timeslicer-calendar-type {
        margin: .5em 0;
        @include flex(1);

        label {
            padding-right: 1em;
            input {
                margin-right: .3em;
            }
        }
    }

    .timeslicer-summary {
        &:hover {
            cursor: pointer;
        }

        span.chevron {
            margin-right: .5em;

            i { @include transition(transform .3s); }
            &.rotate i { @include transform(rotate(90deg)); }
        }
    }

    .timeslicer-title {
        @include flex(1);
        background-color: #535353;
        color: white;
        padding: .2em 1em;
    }

    .timeslicer-body {
        //@include flex(1);
        flex: 0 1 50%;
        min-width: 0;
        overflow: hidden;
        @include flexbox();
        @include flex-direction(column);
        .timeslicer-body-scroll {
            @include flex(1);
            white-space: nowrap;
            overflow: auto;



            .timeslicer-block {
                display: inline-block;
                margin-left: 1px;
                margin-right: 1px;
                border: 1px solid $ui-black-blue;
                width: 12em;

                .timeslicer-block-title, .timeslicer-month {
                    border-bottom: 1px solid $ui-black-blue;
                    text-align: center;
                    

                    &.unselectable {
                        opacity: 0.7;
                        background-color: $ui-grey !important;
                    }
                    &.selected {
                        background: $ui-green-highlight !important;;
                        color: $ui-white !important;

                    }
                }

                .timeslicer-month:last-child {
                    border-bottom: none;
                }

                .timeslicer-block-title {
                    background-color: rgba(147, 186, 54, 0.3);
                    font-weight: bold;
                }

                .timeslicer-block-body {
                    @include flexbox();
                    @include flex-direction(row);

                    .timeslicer-q-block {
                        @include flex(1);
                        border-left: 1px solid $ui-black-blue;

                        .timeslicer-block-title {
                            font-size: 0.9em;
                            background-color: rgba(123, 172, 203, 0.5);
                        }

                        .timeslicer-month {
                            font-size: 0.8em;
                            background-color: $ui-soft-yellow;
                        }
                    }

                    .timeslicer-q-block:first-child {
                        border-left-width: 0;
                    }
                }

                .timeslicer-block-title:not(.unselectable):hover, .timeslicer-month:not(.unselectable):hover {
                    background-color: $ui-blue-highlight !important;
                    color: $ui-white !important;
                    cursor: pointer;
                }

                .timeslicer-unselectable {
                    opacity: 0.7;
                    background-color: $ui-grey !important;
                }
            }
        }
    }
}