// -------------------------------------------------------------------
// :: MEDIA QUERIES
// -------------------------------------------------------------------
// Major breakpoints should be set in base/_typography.scss
// Minor breakpoint should be added where needed (inline)

$mobile: 30em; // 480px
$tablet: 45em; // 720px
$desktop: 60em; // 960px
$desktop-xl: 71.25em; // 1140px

$breakpoints: (
        'small': (min-width: 767px),
        'medium': (min-width: 992px),
        'large': (min-width: 1200px),
        'x-large': (min-width: 1366px),
        'xx-large': (min-width: 1920px),
        'xxx-large': (min-width: 2560px)
) !default;

// -------------------------------------------------------------------
// :: COLORS
// -------------------------------------------------------------------

$incofin-blue: #23b8d1;
$incofin-green: #93c11f;

$ui-white: #F6F6F6;
$ui-off-white: #FFFCE5;

$ui-brown: #535353;

$ui-light-grey: #DDDCD3;
$ui-grey: #7D7D7D;
$ui-dark-grey: #535353;

$ui-blue-highlight: #12B6CF;
$ui-green-highlight: #93BB36;
$ui-orange: #E06B21;

$ui-dark-red: #A51000;
$ui-red: #E62633;
$ui-darker-red: #530000;
$ui-error:#ff4d4f;


$ui-soft-yellow: #FFF19A;
$ui-soft-red: hsla(356, 93%, 73%, 0.833);

$ui-black-blue: #18375B;
$ui-dark-blue: #0062A0;
$ui-soft-blue: #7BACCB;

$light: #ffffff;
$light-extra: #fff;

$dark: #535353;
$dark-extra: #000000;

$primary-color: $ui-red;

$font-color: $dark; // Base
$font-color-medium: #7e8998; // Secondary (eg. image caption)
$font-color-light: $light; // Placeholders in forms
$font-color-disabled: rgba(#1E2F41, 0.30); // Disabled Text

$background: $ui-white;
$side-background: $ui-brown;
$header-background: $ui-light-grey;

$border-color: #D9E5F6; // Borders and rules
$border-color-active: #93ADD1; // Active border

$summary-text-color: $font-color;
$summary-header-color: lighten($summary-text-color, 10%);
$summary-footer-color: $ui-blue-highlight;
$summary-border-color: $ui-blue-highlight;

$slick-arrow-color: $font-color;

// -------------------------------------------------------------------
// :: TYPOGRAPHY
// -------------------------------------------------------------------
// Base-font-sizes should be set in base/_typography.scss
//
// - http://www.awayback.com/revised-font-stack
// - http://practicaltypography.com/system-fonts.html

$font-family-sans: 'Roboto', sans-serif;
$font-family-title: 'texgyreadventor'; //''AvantGardeGothicITCW01B 731063';
$font-family-text: "Roboto";

$font-size-xxxlarge: 65px; // h1
$font-size-xxlarge: 30px; // h2
$font-size-xlarge: 26px; // h3
$font-size-large: 22px; // h4
$font-size-medium: 20px; // h4
$font-size: 16px; // p
$font-size-small: 14px; // h5, small, cite
$font-size-xsmall: 12px; // top menu

// Default border-radius value for input elements,
// alerts, buttons, images (in <figure>), ...

$line-height: 1.7;
$border-radius: 4px; // 5px @ 16px
$box-shadow: 0px 2px 0px 0px rgba($border-color-active, 0.2);

$status-rejected: $ui-red;
$status-approved:$ui-green-highlight;
$status-submitted: #f19836;
$status-in_progress: $ui-dark-grey;
$status-in_review: $ui-dark-grey;
$status-created: $ui-blue-highlight;



// -------------------------------------------------------------------
// :: FLOW IMPORTS
// -------------------------------------------------------------------
