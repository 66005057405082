@import 'scss/variables';
@import 'scss/flexbox_mixin';

div.translation-wrapper {
    margin: 2em 0;

    div.required-wrapper {
        font-size: .7em;

        &.error {
            color: $ui-red !important;
        }
    }

    div.translation-item-container {
        border-bottom: 1px solid $ui-grey;
        @include flexbox();
        @include flex-direction(row);
        @include justify-content(space-between);

        &.multi {
            @include align-items(flex-start);
        }

        > div.Select, div.text-field {
            width: 45%;
        }

        div.text-field {
            background: $ui-soft-yellow;
            color: $ui-blue-highlight;
            min-height: 35px;
            margin-top: 3px;
        }

        div.Select {
            text-align: left;
            background: $ui-soft-yellow;
            color: $ui-blue-highlight;
            min-height: 35px;
            margin-top: 3px;

            &:focus { outline: none; }

            div.Select-control {
                -moz-border-radius: 0;
                -webkit-border-radius: 0;
                border-radius: 0;
                border: none;
                background: transparent;
                color: $ui-white;

                div.Select-value {
                    padding-right: 45px;
                    border: 1px solid $ui-blue-highlight;
                    background-color: $ui-blue-highlight;

                    span.Select-value-label {
                        color: $ui-white;
                    }
                    span.Select-value-icon {
                        border: none;
                        color: $ui-white;
                    }
                }

                div.Select-placeholder {
                    padding-right: 40px;
                }
            }
        }

        button.delete {
            margin-top: 3px;
            background: $ui-green-highlight;
            color: $ui-white;
            cursor: pointer;

            &:before {
                font-family: FontAwesome;
                content: "\f014";
            }
        }
    }

    div.wrapper-actions {
        width: 100%;
        margin-top: 1em;
        height: 3em;
    }
}