@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/flexbox_mixin';

$padding: 1rem;
@mixin label-space-wrap() {
    white-space: nowrap;
    padding-right: 4px;
}

div.display-field {
    text-transform: uppercase;
    border-bottom: 1px solid $ui-grey;
    height: 2.5em;
    width: 100%;
    font-size: .8em;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include flexbox();
    @include flex-direction(column);
    @include transition(all .2s ease-out);
    @include justify-content(center);

    &--value {
        color: $ui-dark-grey;
        background: $ui-light-grey;
        text-align: right;
    }
}

div.display-field-summary {
    @include flexbox();
    @include flex-direction(column);
    @include flex-wrap(wrap);

    > div.display-field--has-children {
        background: $ui-dark-blue;
        font-weight: bold;
        text-transform: uppercase;
        padding: $padding*0.5 $padding;
        color: $font-color-light;
        @include flex(1);
        @include label-space-wrap();
    }

    > div.display-field-children {
        width: 100%;

        div.display-field-summary {
            div.display-field{
                @include label-space-wrap();
                padding-left: $padding * 2 + $padding;
            }
        }

        > div.display-field-summary {
            @include flexbox();
            @include flex-direction(row);
            @include flex-wrap(wrap);

            > div.display-field--has-children {
                @include flex-grow(1);
                color: $ui-white;
                background: $ui-soft-blue;
                font-weight: bold;
                text-transform: uppercase;
                padding-left: $padding + $padding;
            }

            > div.display-field-children {

                > div.display-field-summary {
                    margin-bottom: 0;

                    > div.display-field {
                        @include label-space-wrap();
                        padding-left: $padding * 2 + $padding;
                    }
                }

                > div.display-field-summary {
                    > div.display-field--has-children {
                        color: $ui-dark-grey !important;
                        padding-left: $padding * 2 + $padding;
                        background: rgb(242, 242, 242) !important;
                        text-transform: uppercase;
                        font-weight: normal !important;
                    }

                    > div.display-field-children {
                        > div.display-field-summary {
                            > div.display-field--has-children {
                                color: $ui-dark-grey !important;
                                padding-left: $padding * 2 + $padding;
                                background: rgb(242, 242, 242) !important;
                                text-transform: uppercase;
                                font-weight: normal !important;
                            }
                            > div.display-field {
                                font-style: italic;
                                padding-left: $padding * 3 + $padding;
                            }
                        }


                    }
                }
            }
        }
    }
}