@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/flexbox_mixin';


div.admin-form, div.register-form, div.export-form {
    margin: 1em 0;

    div.form-group {
        border-bottom: 1px solid $ui-grey;
        @include flexbox();
        @include flex-direction(row);
        @include align-items(stretch);

        label {
            width: 25%;
            font-size: .75em;
            @include flexbox();
            @include flex-direction(row);


            &.top-label {
                width: 100%;
            }
        }

        & > div {
            @include flex(1);
        }
        div.block {
            @include flex(none);
            width: 100%;
            margin: .5em 0;

            div.block-children {
                margin: 0 0 1em 2em;
            }
        }

        div.text-field, div.numeric-field, div.date-field, div.pw-wrapper {
           // width: 60%;
            background: $ui-soft-yellow;
            color: $ui-blue-highlight;
            min-height: 35px;
            margin-top: 3px;
        }

        div.date-field {
            @include flexbox();
            @include justify-content(flex-end);
            @include align-items(center);
        }

        div.Select {
           // width: 60%;
            text-align: right;
            background: $ui-soft-yellow;
            color: $ui-blue-highlight;
            min-height: 35px;
            margin-top: 3px;

            &:focus { outline: none; }

            div.Select-control {
                -moz-border-radius: 0;
                -webkit-border-radius: 0;
                border-radius: 0;
                border: none;
                background: transparent;
                color: $ui-white;

                div.Select-value {
                    padding-right: 45px;
                    border: 1px solid $ui-blue-highlight;
                    background-color: $ui-blue-highlight;
                    span.Select-value-label {
                        color: $ui-white;
                    }
                    span.Select-value-icon {
                        border: none;
                        color: $ui-white;
                    }
                }

                div.Select-placeholder {
                    padding-right: 40px;
                }
            }
        }

        &.readOnly {
            div.text-field, div.numeric-field, div.date-field,div.Select {
                color: $ui-dark-grey !important;
                background: $ui-light-grey !important;
            }
        }

        span.covenant-info-guide {
            cursor: pointer;
            padding-right: .5em;

            &:before {
                color: $ui-blue-highlight;
                content: "\f05a";
                font-family: FontAwesome;
                opacity: 1;
                @include transition(opacify .3s ease);
            }

            &:hover:before {
                opacity: 0.8;
            }
        }
    }

    div.form-actions {
        
        button:last-of-type {
            margin: 0 1em;
        }
    }
}