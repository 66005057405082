
@import 'scss/variables';
.inco-tabs {
   
    &::after {
        position: relative;
        display: block;
        width: 100%;
        height: 2px;
        border-top: 1px solid #ccc;
        top: -6px;
        content: " ";
        opacity: 0.5;
    }
    .tabs-nav {
        position: relative;
        display: flex;
        flex: none;
        align-items: center;
        margin: 0 0 5px 0;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.88);
        font-size: 14px;
        line-height: 1.5714285714285714;
        list-style: none;
        
        .tabs-nav-wrap {

            position: relative;
            display: flex;
            flex: auto;
            align-self: stretch;
            overflow: hidden;
            white-space: nowrap;
            transform: translate(0);

            .tabs-nav-list {

                position: relative;
                display: flex;
                transition: opacity 0.3s;

                .tabs-tab {
                    position: relative;
                    -webkit-touch-callout: none;
                    -webkit-tap-highlight-color: transparent;
                    display: inline-flex;
                    align-items: center;
                    padding: 12px 0;
                    font-size: 14px;
                    background: transparent;
                    border: 0;
                    outline: none;
                    cursor: pointer;
                    color: rgba(0, 0, 0, 0.88);

                    border-bottom: 2px solid transparent;
                

                    &:hover {
                        color:$ui-blue-highlight;
                        }

                    &.tabs-tab-active {
                        color:$ui-blue-highlight;
                        border-bottom: 2px solid $ui-blue-highlight;
                    }

                    .tabs-tab-btn {
                        outline: none;
                        transition: all 0.3s;
                    }
                }

            }
        }
    }

    .tabs-tab+.tabs-tab {
        margin: 0 0 0 32px;
    }

    .tab-nav-line {
       
    }

}