
@import 'scss/variables';
@import 'scss/mixins';




.fn-formula  {
    font-size: 10px;
    & > div,span {
        display: inline;
        user-select:none;
    }
    .fn-item-toggle {
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
    .fn-item {
        display: inline-flex;
        flex-direction: column;
        .fn-item-body {
           // padding-left: 0.2em;
           // padding-right: 0.2em;
        }
        .fn-item-value {
            border-top: 1px dashed;
            text-align: center;
            background: lightgoldenrodyellow;
        }
    }
    
    .fn-formula-result {
        font-size: 1.5em;
    }

    .fn-highlight-info {
        font-style: italic;
    background: lightgoldenrodyellow;
    font-size: 0.8em;
    }

    .fn-parenthesis-block {
        color: black;
        display: inline;
        .fn-parenthesis {
            display: inline;
            &.fn-p-open {

            }
            &.fn-p-close {

            }
            
        }
        .fn-parenthesis-body {
            padding-left:0.2em;
            padding-right:0.2em;
            display: inline;
        }

        &:hover:not(:has(.fn-parenthesis-block:hover)) {
            & >.fn-parenthesis {
            font-size: 1.3em;
            color:$ui-blue-highlight;
            font-weight: 700;
            }
        }
    }

    .fn-period {

    }

    .fn-variable {
        text-decoration: none;
        display: inline-flex;
        flex-direction: column;
        border-bottom: 1px dotted currentColor;
       // border:1px dashed #000;
        padding:0.2em;
        .fn-variable-formula {
            padding-left: 0.2em;
             padding-right: 0.2em;
        }

        .fn-variable-value{
            border-top: 1px dashed;
            text-align: center;
            background: lightgoldenrodyellow;
        }
        
        .fn-period {
            //border:1px dashed currentColor;
            background-color: #ffe58f;
            padding-left:0.2em;
            padding-right:0.2em;
        }
        .fn-var-config {
            
            padding-left: 0.2em;
            margin-right: 0.2em;
            padding-right: 0.2em;
            display:inline-block;
        }
        .fn-var-path {

        }
        &:hover {
            cursor: pointer;
            .fn-var-path {
                text-decoration: underline;
            }
            
        }
    }

    .fn-operator {
        display: inline;
        .fn-operator-char {
            font-size: 1.5em;
            font-weight: 700;
            color:$ui-dark-blue;
        }
    }

    .fn-constant {
        .fn-constant-string {
            color:$ui-dark-blue;
        }
        .fn-constant-number {
            color:#ef8f8f;//rgb(88, 125, 16);
            font-weight: bold;
            font-size: 1.1em;
            padding:0.2em;
        }
    }

    .fn-conditional {
        display: block;
      //  display: inline-flex;
       // flex-direction: column;
       // border-left:1px solid $ui-blue-highlight;
        .fn-condition {
            display: block;
            color:$ui-blue-highlight;
            
           // color:$ui-white;
           & > .fn-condition-title {
               // display: block;
               font-weight:700;
                padding-left:1em;
                
            }

            .fn-condition-value {
               // border-top: 1px dashed;
                text-align: center;
                background: lightgoldenrodyellow;
            }
        }
        .fn-condition-true {
            display: block;
            border-left:1px solid $ui-green-highlight;
            
            margin-left: 1em;
            & > .fn-condition-title {
             //   display: block;
                padding-left:1em;
                background:$ui-green-highlight;
                color:$ui-white
            }
            
        }
        .fn-condition-false {
            display: block;
            border-left:1px solid $ui-dark-red;
            margin-left: 1em;
            
            
            & > .fn-condition-title {
               // display: block;
                padding-left:1em;
                background:$ui-dark-red;
                color:$ui-white
            }
        }

        .fn-condition-case {
            display: block;
            padding-left: 20px;
        }

        &.fn-conditional-result-true {
            .fn-condition-value {
                color: $ui-green-highlight;
            }
            .fn-condition-false  {
                opacity: 0.6;
            }

            .fn-condition-true {
                opacity: 1;
            }
        }
        &.fn-conditional-result-false {
            .fn-condition-value {
                color: $ui-dark-red;
            }
            .fn-condition-false  {
                opacity: 1;
            }

            .fn-condition-true  {
                opacity: 0.6;
            }
        }
    }

    .fn-function {
        color:$ui-dark-blue;
        
        .fn-function-name {
            font-weight: 700;
            font-size: 1.2em;
        }
        .fn-function-body{
            margin-left:0.3em;
            margin-right:0.3em;
        }
        .fn-item-value {
        }

        &.fn-function-multiperiod {
            .fn-function-name:hover {
                cursor: pointer;
                text-decoration: underline;
            }

            .fn-multiperiod-short {
                
                background-color: lightgoldenrodyellow;
                font-style: italic;
            }
        }

    }

}