@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/flexbox_mixin';

.login-select {
  width: 50%;
  padding:0;
  max-width:80%;
  .Select__control {
    border-width: 0px;

    .Select__input {
      &:focus {
        border-width: 0px;
      }


      text-align: right;

     // .Select-value-label {
        color: $ui-blue-highlight !important;
        padding-right: 1em;
     // }
    }
  }
  .Select__menu {
    text-align:right;
  }
}